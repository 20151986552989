import React, { useRef, useEffect, forwardRef, useState } from 'react';

import LessonTitle from './lesson-title';
import constants from '../../lib/constants';
import ReactPlayer from 'react-player';

const lessonTitle = "介紹影片";

const LessonVideo = forwardRef(( {id, videoUrl, isPlaying}, ref) => {
    const [player, setPlayer] = useState()

    // useEffect(() => {
    //     if (videoUrl?.includes('mp4') && ref.current) {
    //         ref.current.load();
    //     }
    // }, [videoUrl]);

    useEffect(() => {
        console.log('videoUrl', videoUrl);
        console.log('isPlaying', isPlaying);
        if (player) {
            player.playing = isPlaying;
        }
    }, [videoUrl, isPlaying, player]);

    return (
        <div className='lesson_content_item' id={id}>
            <LessonTitle title={lessonTitle} />
            <div className="lesson_video" onContextMenu={(e) => e.preventDefault()}>
                {/* {videoUrl?.includes('youtu') &&
                    <iframe width="560" height="315" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                }
                {videoUrl?.includes('mp4') &&
                    <video ref={ref} controls preload="auto" width="100%" height="auto">
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                } */}
                <ReactPlayer
                    url={videoUrl}
                    controls
                    width="100%"
                    height="100%"
                    config={{
                        file: {
                        attributes: {
                            controlsList: "nodownload",
                        },
                        },
                    }}
                    ref={(ele) => { setPlayer(ele) }}
                    playing={isPlaying}
                />
            </div>
        </div>
    )
})

export default LessonVideo;