import { React } from 'react';
import { Link } from "react-router-dom";

import iconWatchGray from "../../img/icon_watch-gray.svg";
import iconWatchBlack from "../../img/icon_watch-black.svg";
import iconListenGray from "../../img/icon_listen-black.svg";
import iconListenBlack from "../../img/icon_listen-gray.svg";
import iconReadGray from "../../img/icon_read-gray.svg";
import iconReadBlack from "../../img/icon_read-black.svg";
import iconCartGray from "../../img/icon_cart-gray.svg";
import iconCartBlack from "../../img/icon_cart-black.svg";
import iconUserGray from "../../img/icon_user-gray-m.svg";
import iconUserBlack from "../../img/icon_user-black.svg";
import iconHomeGray from "../../img/icon_home-gray.svg";
import iconHomeBlack from "../../img/icon_home-black.svg";
// 新增
import iconWatchColor from "../../img/icon_watch_color.svg";
import iconListenColor from "../../img/icon_listen_color.svg";
import iconReadColor from "../../img/icon_read_color.svg";
import iconLesson from "../../img/icon_lesson.svg";
// 新增

function HeaderNavMLoginStatic() {
    return (
        <nav className='header_nav-m d-md-none d-block'>
            <div className="header_nav_user login"><span>H</span></div>
            <ul className="header_nav-m_list">
                <li>
                    <Link className='active' to="/watch"><LinkWatch /></Link>
                    <Link to="/hear"><LinkHear /></Link>
                    <Link to="/read"><LinkRead /></Link>
                    {/* <Link to="/"><LinkCart /></Link> */}
                    <Link to="/"><LinkLesson /></Link>
                    <Link to="/member/mylessons"><LinkUser /></Link>
                    <Link to="/"><LinkHome /></Link>
                </li>
            </ul>
            <a href="" className="btn-common btn-logout">登出</a>
        </nav>
    );
}

export default HeaderNavMLoginStatic;

const LinkWatch = () => (
    <div className="d-flex align-items-center header_nav-m_item">

        {/* 改 */}
        <img className='header_nav-m_icon' src={iconWatchColor} alt="" />
        {/* 改 */}

        <span>看</span>
    </div>
)

const LinkHear = () => (
    <div className="d-flex align-items-center header_nav-m_item">

        {/* 改 */}
        <img className='header_nav-m_icon' src={iconListenColor} alt="" />
        {/* 改 */}

        <span>聽</span>
    </div>
)

const LinkRead = () => (
    <div className="d-flex align-items-center header_nav-m_item">

        {/* 改 */}
        <img className='header_nav-m_icon' src={iconReadColor} alt="" />
        {/* 改 */}

        <span>讀</span>
    </div>
)

const LinkCart = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className='header_nav-m_icon normal' src={iconCartGray} alt="" />
        <img className='header_nav-m_icon active' src={iconCartBlack} alt="" />
        <span>買書</span>
    </div>
)

const LinkUser = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className='header_nav-m_icon user normal' src={iconUserGray} alt="" />
        <img className='header_nav-m_icon user active' src={iconUserBlack} alt="" />
        <span>會員專區</span>
    </div>
)

const LinkHome = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className='header_nav-m_icon normal' src={iconHomeGray} alt="" />
        <img className='header_nav-m_icon active' src={iconHomeBlack} alt="" />
        <span>回首頁</span>
    </div>
)

// 新增
const LinkLesson = () => (
    <div className="d-flex align-items-center header_nav-m_item">
        <img className='header_nav-m_icon' src={iconLesson} alt="" />
        <span>我的課程</span>
    </div>
)
// 新增