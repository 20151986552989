import { React, useState } from "react"

import ForgetPassword from "./forget-password"
import PopupSignin from "./popup-signin"
import PopupLoginTemplate from "../popup/popup-login-template"
import PopupSiginTemplate from "../popup/popup-sigin-template"

function LoginLink() {
  const [buttonPopupForget, setButtonPopupForget] = useState(false)
  const [buttonPopupSigin, setButtonPopupSigin] = useState(false)
  return (
    <ul className="login_tab d-flex align-items-center justify-content-end">
      <PopupLoginTemplate
        trigger={buttonPopupForget}
        setTrigger={setButtonPopupForget}
      >
        <ForgetPassword onOk={() => setButtonPopupForget(false)} />
      </PopupLoginTemplate>
      <PopupSiginTemplate
        trigger={buttonPopupSigin}
        setTrigger={setButtonPopupSigin}
      >
        <PopupSignin />
      </PopupSiginTemplate>
      <li onClick={() => setButtonPopupForget(true)}>忘記密碼</li>
      <li onClick={() => setButtonPopupSigin(true)}>尚未註冊</li>
    </ul>
  )
}

export default LoginLink
