import React from 'react';

function AboutItem({ about }) {
    return (
        <div className='about_content'>
            {
                about.map(data => (
                    <div className="about_item">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="about_text">
                                    <h3>{data.title}</h3>
                                    <p>{data.content}</p>
                                </div>
                            </div>
                            {data.pic &&
                                <div className="col-lg-4">
                                    <div className="about_pic">
                                        <img src={data.pic} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default AboutItem;