import React, { useEffect, useState } from 'react';

import Title from '../component/common/title';
import MemberNav from '../component/member/member-nav';
import AccountBasic from "../component/member/account-basic"
import AccountInterest from "../component/member/account-interest"
import AccountNotify from '../component/member/account-notify';
import AccountManage from '../component/member/account-manage';

import iconHead from '../img/icon_head.svg';
import apiService from '../service/apiService';
import RTUtils from '../lib/rt-utils';
import PopupHint from '../component/popup/popup-hint-template-1';

const PageTitle = { pic: iconHead, name: "我的資料", parent: "會員專區" };
const NavActiveIndex = 4;

function MemberAccountStatic() {
    const [user, setUser] = useState()
    const [errorMessage, setErrorMessage] = useState("")
    const [showPopup, setShowPopup] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await apiService.apiGetUserInfo()
                setUser(user)
                // console.log(user)
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [])

    const onChange = (u) => {
        setUser(u)
    }

    const validateInputs = () => {
        if (!RTUtils.validateFullName(user.fullName)) {
            showErrorMessage("姓名長度過短")
            return false
        }
        if (!RTUtils.validateMobilePhoneTW(user.phoneNumber)) {
            showErrorMessage("手機號碼格式有誤")
            return false
        }
        if (user.gender.length === 0) {
            showErrorMessage("請選擇性別")
            return false
        }
        if (user.birthYear.length === 0) {
            showErrorMessage("請選擇出生年")
            return false
        }
        if (user.birthMonth.length === 0) {
            showErrorMessage("請選擇出生月")
            return false
        }
        if (user.address.length === 0) {
            showErrorMessage("請選擇居住地")
            return false
        }
        if (user.family.length === 0) {
            showErrorMessage("請選擇家庭狀況")
            return false
        }
        if (user.topics.length === 0) {
            showErrorMessage("請勾選您有興趣的主題")
            return false
        }
        return true
    }

    const showErrorMessage = (msg) => {
        setErrorMessage(msg)
        setTimeout(() => {
            setErrorMessage("")
        }, 2000)
    }

    const handleSubmit = async () => {
        if (!validateInputs) return
        try {
            // const resp =
            await apiService.apiUpdateUser(user)
            // console.log('update response', resp)
            setShowPopup(true)
        } catch (e) {
            console.log("update error", e)
            setErrorMessage(`更新失敗：${e.response.data.error.message}`)
        }
    }

    return (
        <div className='container'>
            <PopupHint
                trigger={showPopup}
                setTrigger={setShowPopup}
                title="更新完成"
                message="您的個人資訊已經更新。"
                onOk={() => setShowPopup(false)}
            />
            <div className="title_content title-member">
                <Title data={PageTitle} />
            </div>
            <div className="member_page page account">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 mb-4">
                        <MemberNav active={NavActiveIndex} />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="member_content">
                            {/* <AccountNotify /> */}
                            <AccountBasic user={user} onChange={onChange} />
                            <AccountInterest user={user} onChange={onChange} />
                            <AccountManage user={user} />
                        </div>
                        <button className='btn-common btn-send' onClick={handleSubmit}>確認送出</button>
                        <p className='text-center mt-3'>{errorMessage}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberAccountStatic;