const constants = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // baseUrl: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8080/api/v1' : 'https://api-dev.readtoday.com.tw/api/v1'
  },
  cartUrl: 'https://p4.groupbuyforms.tw/94xbs',
  facebookUrl: 'https://zh-tw.facebook.com/grimmpressclub/',
  instagramUrl: 'https://www.instagram.com/grimmpress/',
  youtubeUrl: 'https://www.youtube.com/user/MrGrimmpress',
  lineUrl: 'https://page.line.me/ece5625g',
  paymentUrl: process.env.REACT_APP_PAYMENT_GATEWAY,
  productType: {
    watch: 0,
    listen: 1,
    read: 2,
    lesson: 3,
  },
  imageBaseUrl: 'https://d2cgzwr7140f0n.cloudfront.net/images',
  contentBaseUrl: 'https://d2cgzwr7140f0n.cloudfront.net/media',
  contentPageSize: 10,
  orderLabels: [
    { category: 'default', label: '由新到舊', value: 'desc' },
    { category: 'default', label: '由舊到新', value: 'asc' },
    { category: 'hot', label: '由多到少', value: 'desc' },
    { category: 'hot', label: '由少到多', value: 'asc' },
  ],
  imageUrl(imageName) {
    return `${this.imageBaseUrl}/${imageName}`
  },
  topicOptions: [
    "教育",
    "閱讀",
    "電影",
    "音樂",
    "旅遊",
    "繪畫",
    "心理勵志",
    "商業理財",
    "親子關係與教育",
    "文學",
    "動畫/漫畫",
    "自然科學",
    "美食",
    "運動",
    "社會/政治",
    "購物",
    "3C科技",
  ],
  familyOptions: ["有小孩", "沒有小孩"],
  lineCallbackUrl: (process.env.NODE_ENV !== 'production') ? 'http://localhost:3000/line-auth' : 'https://www.readtoday.com.tw/line-auth',
}
export default constants