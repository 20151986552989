import React, { useState } from 'react';

import IconSearch from '../../img/icon_search.svg';

function FilterSearchStatic({ onKeywordChange }) {
    const [keyword, setKeyword] = useState('')
    const onSearch = (e) => {
        e.preventDefault();
        onKeywordChange(keyword)
    }
    return (
        <div>
            <label className='input_label' htmlFor="">關鍵字</label>
            <div className="input">
                <input className='input_content' type="text" placeholder='請輸入關鍵字' value={keyword} onChange={(e) => { setKeyword(e.target.value) }} />
                <button className='input_btn' onClick={(e) => onSearch(e)}><img src={IconSearch} alt="" /></button>
            </div>
        </div>
    );
}

export default FilterSearchStatic;