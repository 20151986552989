import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import IconTag from '../../img/icon_tag.svg';
import IconTagActive from '../../img/icon_tag-active.svg';

import PubSub from "pubsub-js"
import constants from '../../lib/constants';
import useUserStatus from '../../hooks/useUserStatus';
import RTUtils from '../../lib/rt-utils';
import apiService from '../../service/apiService';


function CardLesson({ lessonData, requireLogin = false }) {
    const navigate = useNavigate()
    const isLogin = useUserStatus('')

    // if priceSales has value, and current time is between salesStart and salesEnd, show priceSales
    const discount = lessonData.priceSales && (lessonData.price != lessonData.priceSales) && (new Date(lessonData.salesStart) < Date.now()) && (new Date(lessonData.salesEnd) > Date.now())
    const [timeRemaining, setTimeRemaining] = useState('')

    const handleClick = (e) => {
        e.preventDefault()
        if (requireLogin) {
            PubSub.publish("REQUIRE_LOGIN", e.target.to)
            console.log(e.target.to)
        }
        if (lessonData.isPurchased) {
            if (lessonData.productType === constants.productType.lesson) {
                navigate(`/classroom/${lessonData.seriesId}`)
            } else if (lessonData.productType === constants.productType.watch) {
                navigate(`/watch`)
            } else if (lessonData.productType === constants.productType.listen) {
                navigate(`/listen`)
            } else if (lessonData.productType === constants.productType.read) {
                navigate(`/read`)
            } else {
                navigate(`/lesson-content/${lessonData.seriesId}`)
            }
        } else {
            if (lessonData.productType === constants.productType.watch) {
                navigate(`/watch`)
            } else if (lessonData.productType === constants.productType.listen) {
                navigate(`/listen`)
            } else if (lessonData.productType === constants.productType.read) {
                navigate(`/read`)
            } else {
                navigate(`/lesson-content/${lessonData.seriesId}`)
            }
        }
    }

    // My Collection
    const [isLike, setIsLike] = useState(false)

    useEffect(() => {
        setIsLike(lessonData.isLiked)
    }, [lessonData.isLiked]);

    const onAdd = async () => {
        try {
            const data = await apiService.addToMyCollections({ seriesId: lessonData.seriesId, productId: null });
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    };

    const onDelete = async () => {
        try {
            const data = await apiService.deleteFromMyCollections({ seriesId: lessonData.seriesId, productId: null });
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickLike = () => {
        if (isLike) {
            onDelete();
        } else {
            onAdd();
        }
        setIsLike(!isLike);
    }
    //////////////////////

    useEffect(() => {
        let timer = null;

        if (lessonData) {
            console.log(lessonData.salesEnd)
            const updateTimeRemaining = () => {
                setTimeRemaining(RTUtils.formatTimeRemaining(lessonData.salesEnd));
            };

            updateTimeRemaining(); // Call once immediately
            timer = setInterval(updateTimeRemaining, 1000); // Then update every second
        }

        // Clear the interval when the component is unmounted or lessonData changes
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [lessonData]);


    return (
        <div className="lessonItem">
            <Link onClick={handleClick}>
                <div className="lessonItem_top">
                    <div className="lessonItem_pic"><img src={`${constants.imageBaseUrl}/${lessonData.image}`} alt="" /></div>
                    <div className="lessonItem_top_text">
                        <h3 className="lessonItem_title">{lessonData.name}</h3>
                        <p className="lessonItem_teacher">{lessonData.author}</p>
                    </div>
                </div>
                {lessonData.isFinished &&
                    <div>
                        <p className='lessonItem_finish'>已完成</p>
                    </div>
                }
                {!lessonData.isFinished && !lessonData.isPurchased &&
                    <div>
                        {discount && <p className="lessonItem_time">{timeRemaining}</p>}
                        <div className="d-flex align-items-center">
                            <h4 className={discount ? 'lessonItem_price discount' : "lessonItem_price"}>NT$ {discount ? lessonData.priceSales : lessonData.price}</h4>
                            {discount && <span className='lessonItem_origin'>{lessonData.price}</span>}
                        </div>
                    </div>
                }
            </Link>
            {isLogin && lessonData.productType >= constants.productType.lesson && <div className='lessonItem_btn'>
                {lessonData.isPurchased && lessonData.productType == constants.productType.lesson && <Link className='btn-common btn-gotoclass' to={`/classroom/${lessonData.seriesId}`} onClick={(handleClick)}>上課去</Link>}
                <div className="btn-icon btn-favorite-m" onClick={() => handleClickLike()}>
                    {!isLike && <img className='icon-normal' src={IconTag} alt="" />}
                    {isLike && <img className='icon-normal' src={IconTagActive} alt="" />}
                </div>
            </div>}
        </div>
    );
}

export default CardLesson;