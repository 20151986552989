import {React, useState} from 'react';

import FilterSearchStatic from '../../component/common/filter_search-static';
import FilterMonthStatic from '../../component/common/filter_month';
import FilterArrangeStatic from '../../component/common/filter_arrange';
import PopupCommon from '../popup/popup-common'

import IconFilter from '../../img/icon_filter.svg';

function FilterIconStatic(){
    const [buttonFilter, setButtonFilter] = useState(false);
    return (
        <div>
            <button className='btn-icon' onClick={() => setButtonFilter(true)}>
                <img src={IconFilter} alt="" />
            </button>
            <PopupCommon trigger={buttonFilter} setTrigger={setButtonFilter}>
                <h5 className='popup-common_title'>搜尋與調整</h5>
                <div className="mb-4"><FilterSearchStatic /></div>
                <div className="mb-4"><FilterMonthStatic /></div>
                <div className="mb-4"><FilterArrangeStatic /></div>
            </PopupCommon>
        </div>
    );
}

export default FilterIconStatic;