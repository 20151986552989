import React from 'react';
import { Link, useLocation } from "react-router-dom";


function MemberTab(){
    const path = useLocation().pathname;

    return (
        <nav>
            <ul className='member_tab d-flex'>
                <li>
                    <Link className={`${path.includes('member-favorite')?'active':''}`} to="/member-favorite">我的最愛</Link>
                </li>
                <li>
                    <Link className={`${path.includes('member-record')?'active':''}`} to="/member-record">兌換與紀錄</Link>
                </li>
                <li>
                    <Link className={`${path.includes('member-account')?'active':''}`} to="/member-account">帳號設定</Link>
                </li>
            </ul>
        </nav>
    );
}

export default MemberTab;