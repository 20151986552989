import React, { useEffect, useRef, useState } from 'react';

import LessonTabMessageName from './lesson-tab-message_name';
import userService from '../../service/userService';
import useUserStatus from '../../hooks/useUserStatus';
import apiService from '../../service/apiService';

function LessonMessage({ seriesId }) {
    const [username, setUsername] = useState('')
    const [editingCommentId, setEditingCommentId] = useState('')
    const [editingText, setEditingText] = useState('')
    const [replyingCommentId, setReplyingCommentId] = useState(null)
    const [comments, setComments] = useState(undefined)
    const isLoggedIn = useUserStatus('')

    // Add a ref to track if the component is mounted
    const isMounted = useRef(true)

    useEffect(() => {
        console.log('isLoggedIn', isLoggedIn)
        console.log('user', userService.getUser())
        if (isLoggedIn && userService.getUser() !== null) {
            setUsername(userService.getUser().fullName)
        }
        // When the component unmounts, set isMounted to false
        return () => {
            isMounted.current = false
        }
    }, [isLoggedIn])

    const postComment = async (comment, replyTo) => {
        try {
            await apiService.postLessonComment({ id: seriesId, comment: comment, replyTo: replyTo })
            setEditingText('')
            loadData(seriesId)
        } catch (error) {
            console.log(error)
        }
    }

    const editComment = async (commentId, comment) => {
        try {
            await apiService.updateLessonComment({ id: seriesId, commentId: commentId, comment: comment })
            loadData(seriesId)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteComment = async (commentId) => {
        try {
            await apiService.deleteLessonComment({ id: seriesId, commentId: commentId })
            loadData(seriesId)
        } catch (error) {
            console.log(error)
        }
    }

    const loadData = async (id) => {
        try {
            const c = await apiService.getLessonComment({ id: id })
            if (isMounted.current) {
                setComments(c)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onEditComment = (comment) => {
        setEditingCommentId(comment.id)
        setEditingText(comment.comment)
    }

    const onCancelEditComment = () => {
        setEditingCommentId(null)
        setEditingText('')
    }

    const onReplyComment = (comment) => {
        setReplyingCommentId(comment.id)
        setEditingText('')
    }

    const onCancelReply = () => {
        setReplyingCommentId(null)
        setEditingText('')
    }

    const onSubmitComment = () => {
        if (editingCommentId) {
            editComment(editingCommentId, editingText)
        } else {
            postComment(editingText, replyingCommentId)
        }
        setEditingCommentId(null)
        setReplyingCommentId(null)
        setEditingText('')
    }

    const onChangeEditingText = (e) => {
        setEditingText(e.target.value)
    }

    useEffect(() => {
        if (seriesId) {
            loadData(seriesId)
        }
    }, [seriesId])

    return (
        <div>

            {/* 自己留言 */}
            {isLoggedIn && <div className="lesson_message">
                <div className="lesson_message_head">
                    <LessonTabMessageName name={username} time={''} isReply={false} />
                </div>
                <div className="lesson_message_content">
                    <textarea className="lesson_message_input" name="" id="" cols="30" rows="10" value={editingText} onChange={(e) => onChangeEditingText(e)}></textarea>
                    <button className='btn-common btn-big mx-auto mt-5' onClick={() => postComment(editingText, null)}>送出</button>
                </div>
            </div>}

            {comments?.map((comment) => {
                return (
                    <div className="lesson_message" key={comment.id}>
                        <div className="lesson_message_head">
                            <LessonTabMessageName name={comment.name} time={comment.updatedAt} isReply={false} />
                        </div>
                        {!editingCommentId && <div className="lesson_message_content">
                            <p>{comment.comment}</p>
                        </div>}
                        {comment.isOwner && !editingCommentId &&
                            <div className='d-flex lesson_message_btn'>
                                <button className='btn-common btn-small mr-3' onClick={() => onEditComment(comment)}>編輯</button>
                                <button className='btn-common btn-small' onClick={() => deleteComment(comment.id)}>刪除</button>
                            </div>
                        }
                        {editingCommentId === comment.id &&
                            <div className="lesson_message_reply_content">
                                <textarea className="lesson_message_input" name="" id="" cols="30" rows="10" value={editingText} onChange={(e) => onChangeEditingText(e)}></textarea>
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <button className='btn-common btn-small mx-2' onClick={() => onCancelEditComment()}>取消</button>
                                    <button className='btn-common btn-small mx-2' onClick={() => onSubmitComment()}>送出</button>
                                </div>
                            </div>
                        }
                        {!comment.isOwner && isLoggedIn &&
                            <button className='btn-common btn-big lesson_message_btn' onClick={() => onReplyComment(comment)}>回覆留言</button>
                        }
                        {replyingCommentId === comment.id &&
                            <div className="lesson_message_reply_content">
                                <textarea className="lesson_message_input" name="" id="" cols="30" rows="10" value={editingText} onChange={(e) => onChangeEditingText(e)}></textarea>
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <button className='btn-common btn-small mx-2' onClick={() => onCancelReply()}>取消</button>
                                    <button className='btn-common btn-small mx-2' onClick={() => onSubmitComment()}>送出</button>
                                </div>
                            </div>
                        }
                        {comment.replies?.map((reply) => {
                            return (<div className="lesson_message_reply" key={reply.id}>
                                <div className="lesson_message_head d-flex align-items-center justify-content-between">
                                    <LessonTabMessageName name={reply.name} time={reply.updatedAt} isReply={true} />
                                </div>
                                {editingCommentId !== reply.id && <p>{reply.comment}</p>}
                                {reply.isOwner && editingCommentId !== reply.id &&
                                    <div className='d-flex lesson_message_btn'>
                                        <button className='btn-common btn-small mr-3' onClick={() => onEditComment(reply)}>編輯</button>
                                        <button className='btn-common btn-small' onClick={() => deleteComment(reply.id)}>刪除</button>
                                    </div>}
                                {editingCommentId === reply.id &&
                                    <div className="lesson_message_reply_content">
                                        <textarea className="lesson_message_input" name="" id="" cols="30" rows="10" value={editingText} onChange={(e) => onChangeEditingText(e)}></textarea>
                                        <div className="d-flex align-items-center justify-content-center mt-3">
                                            <button className='btn-common btn-small mx-2' onClick={() => onCancelEditComment()}>取消</button>
                                            <button className='btn-common btn-small mx-2' onClick={() => onSubmitComment()}>送出</button>
                                        </div>
                                    </div>
                                }
                            </div>)
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export default LessonMessage;