import React, { useEffect, useState } from "react"

function Check({ data, onChange, checked }) {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => setIsChecked(checked), [checked])

  function handleActive(event) {
    setIsChecked(!isChecked)
    onChange(!isChecked)
  }

  return (
    <div className={`check d-flex align-items-center ${isChecked?'active':''}`} onClick={handleActive}>
      <div className="check_box"></div>
      <span>{data}</span>
    </div>
  )
}

export default Check
