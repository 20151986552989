import React from 'react';

function TitleContent({titleData}){
    return (
        <div className="d-flex align-items-center title-content">
            <div className="title-content_line"></div>
            <h1>{titleData}</h1>
        </div>
    );
}

export default TitleContent;