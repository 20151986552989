import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ListItem from './list-item';

import IconUnion from '../../img/icon_union.svg';

function ListContent({section, contentList, showAllLink, detailLink}){
  const navigate= useNavigate()
    return (
        <div className="list_content">
            <div className="d-flex align-items-center justify-content-between">
                <div className="title-common d-flex align-items-center" onClick={() => navigate(`${showAllLink}?category=${section.category}`)}>
                    <img src={section.icon} alt="" />
                    <h2>{section.title}</h2>
                </div>
                <Link className='btn-icontext' to={`${showAllLink}?category=${section.category}`}>
                    <img src={IconUnion} alt="" />
                    <span>顯示全部</span>
                </Link>
            </div>
            <div className="row">
                {
                  contentList.map(content =>(
                        <div className="col-lg-4 col-md-6" key={content.id}>
                            <ListItem content={content} detailLink={detailLink}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ListContent;