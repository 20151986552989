import React, { useEffect, useState } from 'react';

import Title from '../component/common/title';
import MemberNav from '../component/member/member-nav';
import CardLesson from '../component/list/card_lesson';
import IconEmpty from '../img/icon_empty.svg';

import iconHead from '../img/icon_head.svg';
import apiService from '../service/apiService';

const PageTitle = { pic: iconHead, name: "我的課程", parent: "會員專區" }
const NavActiveIndex = 0;

function MemberMylesson({ requireLogin = false }) {
    const [lessons, setLessons] = useState([])
    // const [page, setPage] = useState(1)
    // const [totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const myLessons = await apiService.getMyLessons()
                setLessons(myLessons.filter(item => item.productType === 3))
                // setLessons(myLessons.content)
                // setPage(myLessons.pageNumber)
                // setTotalPages(myLessons.totalPages)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    // const handlePrev = () => {
    //     if (page === 1) return
    //     setPage(page - 1)
    // }

    // const handleNext = () => {
    //     if (page === totalPages) return
    //     setPage(page + 1)
    // }

    // const handleFirst = () => {
    //     setPage(1)
    // }

    // const handleLast = () => {
    //     setPage(totalPages)
    // }

    // const handlePageChange = (page) => {
    //     setPage(page)
    // }

    return (
        <div className='container'>
            <div className="title_content title-member">
                <Title data={PageTitle} />
            </div>
            <div className="member_page lesson">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 mb-4">
                        <MemberNav active={NavActiveIndex} />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="member_content">
                            <div className="row">
                                {
                                    lessons?.map((content) => (
                                        <div className="col-xl-4 col-md-6" key={content.seriesId}>
                                            <CardLesson lessonData={content} requireLogin={requireLogin} />
                                        </div>
                                    ))
                                }
                            </div>
                            {!lessons || lessons.length === 0 &&
                                <div className="member_empty">
                                    <img src={IconEmpty} alt="" />
                                    <p>{loading ? '讀取中...' : '這裡空空的...'}</p>
                                </div>
                            }
                        </div>
                        {/* {lessons && lessons.length > 0 &&
                            <PaginationStatic
                                page={page}
                                totalPages={totalPages}
                                onPrev={handlePrev}
                                onNext={handleNext}
                                onFirst={handleFirst}
                                onLast={handleLast}
                                onPageChange={handlePageChange}
                            />} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberMylesson;