import { React, useEffect, useState } from 'react';

import TitleContent from '../component/common/title-content';
import QaContent from '../component/lessonContent/qa-content';
import apiService from '../service/apiService';

const title = "常見問與答";
const qa = [
    {
        title: "帳號問題",
        content: [
            {
                title: "線上課程有觀看期限嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，<br></br>您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "有沒有年齡限制？這堂課適合我嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "什麼時候可以開始上課？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "請問「XXXXXXXX課程」課程時數有多長？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "請問這 10 小時的課程，是純網路影片嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "針對課程內容，如果想發問，有什麼管道嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
        ]
    },
    {
        title: "訂單問題 ",
        content: [
            {
                title: "線上課程有觀看期限嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "有沒有年齡限制？這堂課適合我嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "什麼時候可以開始上課？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "請問「XXXXXXXX課程」課程時數有多長？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "請問這 10 小時的課程，是純網路影片嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
            {
                title: "針對課程內容，如果想發問，有什麼管道嗎？",
                content: <div>
                    課程內容沒有觀看期限以及次數限制，您可以依照個人的狀況，自由安排學習進度。購買課程後，只要登入 XXXXXX 平台，在這裡你可以隨時複習、反覆觀看喔！
                </div>,
            },
        ]
    },
];


function Qa() {
    const [qaData, setQaData] = useState(qa);
    useEffect(() => {
        const loadData = async () => {
            const data = await apiService.getFaq();
            setQaData(data);
        }
        loadData();
    }, []);

    return (
        <div className='container qa'>
            <TitleContent titleData={title} />
            <div className="qa_content">
                <QaContent qaItem={qaData} />
            </div>
        </div>
    )
}

export default Qa;