import React, { useEffect, useState } from "react"

// import TestAdio from "../../img/test.mp3"
// import AudioQuick from "../../img/icon_arrow-audio.svg"
// import AudioPause from "../../img/icon_pause.svg"
// import AudioStart from "../../img/icon_start.svg"
// import AudioSound from "../../img/icon_sound.svg"
// import AudioSound2 from "../../img/icon_sound-2.svg"
import ReactAudioPlayer from "react-audio-player"
// import constants from "../../lib/constants"
import apiService from "../../service/apiService"

function Audio({ product, onPlayComplete }) {
  const [player, setPlayer] = useState()
  // Call setHistory
  const trackUsage = async (progress) => {
    // const resp = 
    await apiService.apiPostHistory({ productId: product.id, progress: progress })
    // console.log(resp)
  }

  useEffect(() => {
    const loadHistory = async () => {
      const resp = await apiService.apiGetHistory({ productId: product.id })
      console.log(`progress: ${resp.content[0]?.progress} / duration: ${product.contentLength}`)
      if (player && parseInt(resp.content[0]?.progress) < parseInt(product.contentLength)) {
        player.audioEl.current.currentTime = resp.content[0].progress
      } else {
        player.audioEl.current.currentTime = 0
      }
      // player.audioEl.current.play()
    }
    if (product.id && player) {
      loadHistory()
    }
  }, [player, product])

  useEffect(() => {
    if (player && player.audioEl && player.audioEl.current) {
      const audioElement = player.audioEl.current;
      const preventContextMenu = (e) => e.preventDefault();
      audioElement.addEventListener('contextmenu', preventContextMenu);
  
      // Cleanup function to remove the event listener
      return () => audioElement.removeEventListener('contextmenu', preventContextMenu);
    }
  }, [player]);

  return (
    <div className="container audio">
      {/* <h1 className="audio_title">{product.name}</h1> */}
      <div className="audio_content">
        {/* <div className="audio_progress">
          <div className="audio_progress_bar">
            <div className="audio_progress_dot"></div>
          </div>
          <p className="audio_progress_text">5 / 40 min</p>
        </div> */}
        <div className="audio_icon d-flex align-items-center justify-content-center">
          <ReactAudioPlayer
            src={`${product.contentUri}`}
            autoPlay={true}
            controls
            controlsList={"nodownload"}
            onListen={trackUsage}
            onEnded={(e) => {
              trackUsage(e.target.duration)
              onPlayComplete()
            }}
            onPause={(e) => trackUsage(e.target.currentTime)}
            listenInterval={5000}
            ref={(ele) => { setPlayer(ele) }}
            onContextMenu={(e) => e.preventDefault()} // Prevent right-click
          />
          {/* <img className="audio_speed slow" src={AudioQuick} alt="" />
          <div className="audio_icon_center">
            <img className="audio_pause" src={AudioPause} alt="" />
            <img className="audio_start" src={AudioStart} alt="" />
          </div>
          <img className="audio_speed quick" src={AudioQuick} alt="" />
        </div>
        <div className="audio_sound d-flex align-items-center justify-content-center">
          <img src={AudioSound} alt="" />
          <div className="audio_sound_progress">
            <div className="audio_sound_dot"></div>
          </div>
          <img src={AudioSound2} alt="" /> */}
        </div>
      </div>
    </div>
  )
}

export default Audio
