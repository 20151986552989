import React from "react"
import { Navigation, Pagination, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

// const tab = ["全部顯示", "單元1", "單元2", "單元3", "單元4", "單元5", "單元6", "單元7"]

function ListTab({ seriesList, onSelect, selected }) {
  let tab = [{ seriesId: 0, name: "全部顯示" }, ...seriesList]
  return (
    <div className="list-swiper-box">
      <Swiper
        className="list-swiper d-md-block d-none"
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={15}
        slidesPerView={6}
        navigation
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
      >
        {tab.map((a) => (
          <SwiperSlide key={a.seriesId}>
            <a href="/" className={`btn-tab ${parseInt(selected) === a.seriesId ? 'active' : ''}`} onClick={(e) => {
                e.preventDefault()
                onSelect(a.seriesId)
              }}>
              {a.name}
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="list-swiper-m d-md-none d-block">
        <div className="row">
          {tab.map((a) => (
            <div className="col-6 mt-3" key={a.seriesId}>
              <button className={`btn-tab ${parseInt(selected) === a.seriesId ? 'active' : ''}`} onClick={() => onSelect(a.seriesId)}>
                {a.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListTab
