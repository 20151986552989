import { React, useState } from 'react';

import IconMail from '../../img/icon_mail_yellow.svg';
import IconPhone from '../../img/icon_phone_yellow.svg';
import IconLine from '../../img/icon_line_color.svg';
import IconGoogle from '../../img/icon_google_color.svg';
import IconFb from '../../img/icon_fb_color.svg';
import IconCheckCircle from '../../img/icon_checkCircle.svg';
import IconTie from '../../img/icon_tie.svg';
import constants from '../../lib/constants';

function AccountManage({ user }) {
    const handleLineConnect = () => {
        console.log('Line connect');
        const clientId = '2003899380';
        const redirectUri = constants.lineCallbackUrl;
        const state = 'connect';
        const scope = 'profile openid';
        window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&scope=${scope}`;
    }

    return (
        <div className='account_setting account_manage'>
            <h2>帳號管理</h2>
            <p className='account_manage_intro'>註冊信箱無法修改，但透過綁定不同帳號可以有多種登入方式；使用手機或社群帳號註冊，我們將自動替您完成一般信箱的驗證。</p>
            {user && <div className="account_manage_content">
                <div className="service_inform_item account_manage_item">
                    <div className="account_manage_left d-flex align-items-center">
                        <div className="service_inform_icon"><img src={IconMail} alt="" /></div>
                        <div className="service_inform_text">
                            <h3>Email</h3>
                            <p>{user.email}</p>
                        </div>
                    </div>
                    <div className="account_manage_btn">
                        <button className='btn-icontext'>
                            <img src={IconCheckCircle} alt="" />
                            <span>已驗證</span>
                        </button>
                    </div>
                </div>
                <div className="service_inform_item account_manage_item">
                    <div className="account_manage_left d-flex align-items-center">
                        <div className="service_inform_icon"><img src={IconPhone} alt="" /></div>
                        <div className="service_inform_text">
                            <h3>行動電話</h3>
                            <p>未驗證</p>
                        </div>
                    </div>
                    <div className="account_manage_btn">
                        <button className='btn-icontext not'>
                            <img src={IconCheckCircle} alt="" />
                            <span>立即驗證</span>
                        </button>
                    </div>
                </div>
                {/* <div className="service_inform_item account_manage_item">
                    <div className="account_manage_left d-flex align-items-center">
                        <div className="service_inform_icon"><img src={IconLine} alt="" /></div>
                        <div className="service_inform_text">
                            <h3>Line帳號</h3>
                            <p>{user.lineId != null ? user.lineId : ''}</p>
                        </div>
                    </div>
                    <div className="account_manage_btn">
                        <button className='btn-icontext' onClick={() => handleLineConnect()}>
                            <img src={IconTie} alt="" />
                            <span>{user.lineId != null ? '已綁定' : '未綁定'}</span>
                        </button>
                    </div>
                </div> */}
                {/* <div className="service_inform_item account_manage_item">
                    <div className="account_manage_left d-flex align-items-center">
                        <div className="service_inform_icon"><img src={IconGoogle} alt="" /></div>
                        <div className="service_inform_text">
                            <h3>Google帳號</h3>
                            <p>123456</p>
                        </div>
                    </div>
                    <div className="account_manage_btn">
                        <button className='btn-icontext'>
                            <img src={IconTie} alt="" />
                            <span>已綁定</span>
                        </button>
                    </div>
                </div>
                <div className="service_inform_item account_manage_item">
                    <div className="account_manage_left d-flex align-items-center">
                        <div className="service_inform_icon"><img src={IconFb} alt="" /></div>
                        <div className="service_inform_text">
                            <h3>Facebook帳號</h3>
                            <p>未綁定</p>
                        </div>
                    </div>
                    <div className="account_manage_btn">
                        <button className='btn-icontext not'>
                            <img src={IconTie} alt="" />
                            <span>立即綁定</span>
                        </button>
                    </div>
                </div> */}
            </div>}
        </div>
    );
}

export default AccountManage;