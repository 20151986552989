import React from "react"
import { Navigation, Pagination, Autoplay, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import constants from "../../lib/constants"



function HomeBanner({ banners }) {
  return (
    <Swiper
      className="banner banner-home"
      modules={[Navigation, Pagination, Autoplay, A11y]}
      slidesPerView={1}
      navigation
      speed={800}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
    >
      {banners?.map((banner) => (
        <SwiperSlide key={banner.id}>
          <a href={banner.link}><img className="banner_pic" src={`${constants.imageBaseUrl}/banner/${banner.image}`} alt="" /></a>
          <a href={banner.link}><img className="banner_pic-m" src={`${constants.imageBaseUrl}/banner/${banner.imageMobile}`} alt="" /></a>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default HomeBanner
