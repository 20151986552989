import React from 'react';

import IconArrowPage from '../../img/icon_arrow-page.svg';
import IconArrow from '../../img/icon_arrow-small-deep.svg';

function PaginationStatic({ page, totalPages, onPrev, onNext, onFirst, onLast, onPageChange }) {
    const linkStyle = { cursor: 'pointer' };

    return (
        <nav className="pagination">
            <ul className='d-flex align-items-center justify-content-center'>
                <li><a style={linkStyle} className='pagination_arrow extreme prev' onClick={(e) => { e.preventDefault(); onFirst(); }}><img src={IconArrowPage} alt="" /></a></li>
                <li><a style={linkStyle} className='pagination_arrow normal prev' onClick={(e) => { e.preventDefault(); onPrev(); }}><img src={IconArrow} alt="" /></a></li>
                <ul className='pagination_num d-flex align-items-center justify-content-center'>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((index) => (
                        <li key={index}>
                            <a style={linkStyle} className={page === index ? 'active' : ''} onClick={(e) => { e.preventDefault(); onPageChange(index); }}>{index}</a>
                        </li>
                    ))}
                </ul>
                <li><a style={linkStyle} className='pagination_arrow normal next' onClick={(e) => { e.preventDefault(); onNext(); }}><img src={IconArrow} alt="" /></a></li>
                <li><a style={linkStyle} className='pagination_arrow extreme next' onClick={(e) => { e.preventDefault(); onLast(); }}><img src={IconArrowPage} alt="" /></a></li>
            </ul>
        </nav>
    );
}
export default PaginationStatic;