import {React} from 'react';



function PopupCommon(props){
    return (props.trigger) ? (
        <div className="popup">
            <div className='popup-common_content position-relative'>
                <svg className='popup-close' onClick={() => props.setTrigger(false)}
                width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.92188" y="0.00390625" width="32.6325" height="2.71937" transform="rotate(45 1.92188 0.00390625)" fill="#979797"/>
                    <rect y="23.0742" width="32.6325" height="2.71937" transform="rotate(-45 0 23.0742)" fill="#979797"/>
                </svg>
                { props.children }
            </div>  
        </div>   
    ) : "";
}

export default PopupCommon;