const userKey = 'readTodayUser'
const usernameKey = 'readTodayUsername'
const userService = {
  getUser: () => {
    const json = localStorage.getItem(userKey)
    if (json) {
      return JSON.parse(json)
    }
    return null
  },
  setUser: (user) => {
    localStorage.setItem(userKey, JSON.stringify(user))
  },
  clearUser: () => {
    window.localStorage.removeItem(userKey)
  },
  isLoggedIn: () => {
    return localStorage.getItem(userKey) !== null
  },
  setUsername: (username) => {
    localStorage.setItem(usernameKey, username)
  },
  getUsername: () => {
    return localStorage.getItem(usernameKey) ?? ""
  }
}

export default userService