import React from 'react';
import { Link } from "react-router-dom";

import ListPic from '../../img/pic-3.png';
import constants from '../../lib/constants';

function ListItemRecordStatic({ orderItem }) {
    return (
        <div className="list_content_item item-record">
            <Link to="/" className='d-flex align-items-center'>
                <div className="list_content_pic"><img src={constants.imageUrl(orderItem.image)} alt="" /></div>
                <div className="list_content_text">
                    <p className="list_content_title">{orderItem.itemName}</p>
                    <p className="list_content_inform">{orderItem.date.replaceAll('-', '.')}</p>
                    <p className="list_content_inform" dangerouslySetInnerHTML={{ __html: orderItem.details }}></p>
                </div>
            </Link>
        </div>
    );
}

export default ListItemRecordStatic;