import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import OrderInformCheck from './order-inform-check';
import { ThreeDots } from 'react-loader-spinner';
import SweetAlert2 from 'react-sweetalert2';

function OrderInform({ checkoutInfo, onUsePromoCode, onPay }) {
    const [promoCode, setPromoCode] = useState('');
    const [coupon, setCoupon] = useState('');
    const [swalProps, setSwalProps] = useState({});
    const onClickPromoCodeIsNotValid = (e) => {
        e.preventDefault()
        setSwalProps({
            show: true,
            title: '折扣代碼沒有生效',
            text: '如果您是經由折扣活動連結進入本站，但折扣代碼未生效，請在折扣網址找到 "pmc=" 字樣，並將 = 後面的文字（例如：RT123456）複製到此處，按下使用即可。',
            icon: 'info',
            confirmButtonColor: '#FCC800',
            iconColor: '#FCC800',
            onConfirm: () => setSwalProps({ show: false })
        });
    }
    const handleClickUsePromoCode = () => {
        if (promoCode) {
            onUsePromoCode(promoCode)
        }
    }
    const handleUnusePromoCode = (e) => {
        e.preventDefault()
        setPromoCode('')
        onUsePromoCode('')
    }

    useEffect(() => {
        if (checkoutInfo?.promoCode) {
            setPromoCode(checkoutInfo.promoCode)
        }
        if (checkoutInfo?.coupon) {
            setCoupon(checkoutInfo.coupon)
        }
    }, [checkoutInfo?.promoCode])

    return (
        <div className='order mb-lg-0 mb-5'>
            <h5>訂單資訊</h5>
            <div className="order_content">
                <div className="order_inform_item">
                    <h4>購買項目</h4>
                    {checkoutInfo && <ul className="order_inform_list">
                        {checkoutInfo.orderItems.map((item, index) => (
                            <li className='d-flex justify-content-between' key={index}>
                                <span>{item.seriesNames}</span>
                                <span>NT$ {item.price}</span>
                            </li>
                        ))}
                    </ul>}
                    {!checkoutInfo && <ThreeDots
                        visible={true}
                        width="50"
                        height={30}
                        color="#4fa94d"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ display: "flex", justifyContent: "center" }}
                        wrapperClass=""
                    />}
                </div>
                {/* <div className="order_inform_item">
                    <h4>首購禮</h4>
                    <ul className="order_inform_list">
                        <li className='d-flex justify-content-between'>
                            <span>名稱名稱名稱</span>
                            <span className='text-decoration-through'>NT$ 72,220</span>
                        </li>
                    </ul>
                </div> */}
                <div className="order_inform_item">
                    <h4>方案</h4>
                    {checkoutInfo?.orderItems.map((item, index) => (
                        <Link className="order_inform_case" key={index} to={'/cart'}>{item.name}</Link>
                    ))}
                </div>
                <div className='order_inform_item'>
                    <h4>優惠券</h4>
                    <Link className="order_inform_case" to="/member-discount">{coupon && coupon.length > 0 ? coupon : "未使用"}</Link>
                </div>
                <div className="order_inform_item order_inform_discount">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4>折扣碼</h4>
                        <Link onClick={(e) => handleUnusePromoCode(e)}>清除</Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <input type="text" className="input-common" placeholder='請輸入' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        <button className='btn-common' onClick={() => handleClickUsePromoCode()}>使用</button>
                    </div>
                    <Link onClick={(e) => onClickPromoCodeIsNotValid(e)}>折扣代碼沒有生效?</Link>
                </div>
                <div className="order_inform_item order_inform_check d-lg-block d-none">
                    <OrderInformCheck checkoutInfo={checkoutInfo} onPay={onPay} />
                </div>
            </div>
            <SweetAlert2 {...swalProps} />
        </div>
    );
}

export default OrderInform;