import React, { useEffect, useState } from 'react';

import IconArrowWhite from '../../img/icon_arrow-white.svg';
import constants from '../../lib/constants';

function FilterArrange({ category, order, onChange }) {
  const [order1, setOrder] = useState('desc')
  const [orderLabels, setOrderLabels] = useState([]);
  useEffect(() => {
    setOrder(order)
    let ol = constants.orderLabels.filter(o => o.category === category)
    if (ol.length === 0)
      ol = constants.orderLabels.filter(o => o.category === 'default')
    setOrderLabels(ol)
  }, [category, order])
  const handleChange = (value) => {
    setOrder(value)
    onChange(value)
  }
  return (
    <div>
      <label className='input_label' htmlFor="">排序</label>
      <div className="input">
        <div className="input_content"></div>
        <div className="input_btn"><img src={IconArrowWhite} alt="" /></div>
        <select className='input_select' value={order1} onChange={({ target }) => handleChange(target.value)}>
          <option value="" disabled>請選擇排序方式</option>
          {orderLabels.map(ol => <option key={ol.label} value={ol.value}>{ol.label}</option>)}
        </select>
      </div>
    </div>
  );
}

export default FilterArrange;