import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
// import { HashRouter as Router } from 'react-router-dom';
import Header from './Header';
import HeaderNew from './Header-new';
import Footer from './Footer';
import RouteView from './RouteView';
import HeaderTags from "../component/header/header-tags"

function App() {
    return (
        <div>
            <Router>
                {/* <HashRouter> */}
                <HeaderTags />
                <Header />
                {/* <HeaderNew /> */}
                <main>
                    <RouteView />
                </main>
                <Footer />
                {/* </HashRouter> */}
            </Router>
        </div>
    );
}

export default App;