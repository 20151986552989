import React from 'react';


function LessonTitle({title}){
    return (
        <div className='lesson_title'>
            <div className="lesson_title_line"></div>
            <h2>{title}</h2>
        </div>
    )
}

export default LessonTitle;