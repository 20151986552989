import React from 'react';
import PubSub from "pubsub-js"
import useUserStatus from '../../hooks/useUserStatus';
import { useNavigate } from 'react-router';
import './title-common.scss'

function TitleCommon({ data }) {
  const isLoggedIn = useUserStatus('')
  const navigation = useNavigate()

  const handleClick = (e) => {
    if (isLoggedIn) {
      navigation(data.link)
    } else {
      e.preventDefault()
      PubSub.publish("REQUIRE_LOGIN", e.target.to)
    }
  }

  return (
    <div className="title-common d-flex align-items-center" onClick={handleClick}>
      <img src={data.pic} alt="" />
      <h2>{data.title}</h2>
    </div>
  );
}

export default TitleCommon;