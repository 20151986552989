import React, { useEffect, useState } from "react"

import ListBanner from "../component/list/list-banner"
import ListItem from "../component/list/list-item"
import ListTab from "../component/list/list-tab"
import Pagination from "../component/common/pagination"

import iconRead from "../img/icon_read_color.svg"
import apiService from "../service/apiService"
import constants from "../lib/constants"
import { useSearchParams } from "react-router-dom"
import { Default } from "react-awesome-spinners"

function Read() {
  const [seriesParams] = useSearchParams()
  const sid = seriesParams.get("sid") ? parseInt(seriesParams.get("sid")) : 0

  const pageSize = 9
  const [banners, setBanners] = useState([])
  const [seriesList, setSeriesList] = useState([])
  const [products, setProducts] = useState([])
  const [curSeriesId, setCurSeriesId] = useState(sid ? parseInt(sid) : 0)
  const [seriesName, setSeriesName] = useState("全部顯示")
  const [productPage, setProductPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: pageSize,
    totalPages: 1,
    first: true,
    last: false,
  })
  const [loading, setLoading] = useState(false)

  // get banners and series data
  useEffect(() => {
    const sid = seriesParams.get("sid")
    console.log("sid = " + seriesParams.get("sid"))
    const fetchData = async () => {
      setLoading(true)
      try {
        const banners = await apiService.apiHomeBanners()
        setBanners(banners)
        const seriesList = await apiService.apiSeries({
          type: constants.productType.read,
        })
        setSeriesList(seriesList)
        if (sid) {
          setCurSeriesId(sid)
        }
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [seriesParams])

  // select series name
  // get products based on selected series
  useEffect(() => {
    const series = seriesList.find((s) => s.seriesId === parseInt(curSeriesId))
    setSeriesName(series ? series.name : "全部顯示")

    const fetchData = async () => {
      let params = {}
      if (curSeriesId === 0) {
        params = {
          productType: constants.productType.read,
          page: productPage,
          size: pageSize,
        }
      } else {
        params = {
          productType: constants.productType.read,
          page: productPage,
          size: pageSize,
          seriesId: curSeriesId,
        }
      }
      const products = await apiService.apiProducts(params)
      setProducts(products.content)
      setPagination({
        pageNumber: products.number + 1,
        pageSize: products.size,
        totalPages: products.totalPages,
        first: products.first,
        last: products.last,
      })
    }
    fetchData()
  }, [seriesList, curSeriesId, productPage])

  const handleSelectTab = (seriesId) => {
    // console.log(`selected ${seriesId}`)
    setCurSeriesId(seriesId)
    const series = seriesList.find((s) => s.seriesId === seriesId)
    setSeriesName(series ? series.name : "全部顯示")
  }

  const handleSelectPage = (pageNumber) => {
    setProductPage(pageNumber)
  }

  return (
    <>
      {loading && (
        <div className="loader">
          <Default color="black" />
          <p>讀取中</p>
        </div>
      )}
      {!loading && (
        <div>
          <ListBanner banners={banners.filter((b) => b.location === "READ")} />
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <ListTab
                seriesList={seriesList}
                onSelect={handleSelectTab}
                selected={curSeriesId}
              />
              <div className="d-lg-block d-none">{/* <ListArrange /> */}</div>
            </div>
            <div className="list_content pb-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="title-common d-flex align-items-center">
                  <img src={iconRead} alt="" />
                  <h2>{seriesName}</h2>
                </div>
                <div className="d-lg-none d-block">{/* <ListArrange /> */}</div>
              </div>
              <div className="row">
                {products &&
                  products.map((product) => (
                    <div className="col-lg-4 col-md-6" key={product.id}>
                      <ListItem content={product} detailLink={"/read-inner"} />
                    </div>
                  ))}
              </div>
            </div>
            <div className="list_pagination">
              <Pagination
                pagination={pagination}
                onSelectPage={handleSelectPage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Read
