import { React, useEffect, useState } from 'react';

import ClassroomVideo from '../component/lessonContent/classroom-video';
import ClassroomContent from '../component/lessonContent/classroom-content';
import ClassroomChapter from '../component/lessonContent/classroom-chapter';
import { useNavigate, useParams } from 'react-router';
import apiService from '../service/apiService';
import useUserStatus from '../hooks/useUserStatus';
import constants from '../lib/constants';
import Audio from '../component/content/audio';
import InnerBanner from '../component/content/inner-banner';
import ContentText from '../component/content/content_text';

function Classroom() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [classRoomData, setClassRoomData] = useState({})
    const [chapters, setChapters] = useState([])
    const [product, setProduct] = useState(undefined)
    // const isLogin = useUserStatus('')
    const navigate = useNavigate()
    const [prevNext, setPrevNext] = useState({hasPrev: false, hasNext: false, prevId: 0, nextId: 0})

    const onSelectLesson = async (lessonId) => {
        try {
            const product = await apiService.apiProduct({ id: lessonId })
            if (product.productType === constants.productType.lesson) {
                // console.log('setProduct', product)
                setProduct(product)
                const prevNext = await apiService.apiPrevNext({ id: lessonId })
                setPrevNext(prevNext)
            }
        } catch (e) {
            if (e.status === 403) {
                // unauthorized or no product for this id
                navigate(`/lesson-content/${id}`)
            } else if (e.status === 404) {
                navigate("/")
            } else {
                // display error
                console.log(e)
                navigate("/")
            }
        }
    }

    const onPlayComplete = async () => {
        if (prevNext.hasNext) {
            onSelectLesson(prevNext.nextId)
            // set current lesson id
            setClassRoomData(classRoomData => ({...classRoomData, currentLessonId: prevNext.nextId}))
        }
    }

    useEffect(() => {
        const loadData = async (seriesId) => {
            if (!seriesId) return
            setLoading(true)
            try {
                const data = await apiService.getClassroom({ id: seriesId })
                setClassRoomData(data)
                const chapters = await apiService.getLessonChapters({ id: seriesId })
                setChapters(chapters)
                if (data.currentLessonId) {
                    const product = await apiService.apiProduct({ id: data.currentLessonId })
                    if (product.productType === constants.productType.lesson) {
                        setProduct(product)
                        const prevNext = await apiService.apiPrevNext({ id: data.currentLessonId })
                        setPrevNext(prevNext)
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        loadData(id)
    }, [id, navigate])

    return (
        <div>
            {product && product.format === 'mp3' && <>
                <InnerBanner product={product} backLink='/lesson' />
                <Audio product={product} onPlayComplete={onPlayComplete}/>
            </>}
            {product && product.format === 'mp4' &&
                <ClassroomVideo product={classRoomData} productId={product.id} videoUrl={product.contentUri}  onPlayComplete={onPlayComplete} />
            }
            {product &&             
                <ContentText product={product} />
            }
            {!product && (classRoomData.format === 'mp4' || classRoomData.format === 'youtube') &&
                <ClassroomVideo product={classRoomData} productId={classRoomData.currentLessonId} videoUrl={classRoomData.contentUri} />
            }
            {!product && classRoomData.format === 'audio' &&
                <Audio product={classRoomData} />
            }
            <ClassroomContent title={classRoomData.title} chapter={chapters} mylesson={1} />
            <ClassroomChapter chapter={chapters} lessonId={classRoomData.currentLessonId} onSelectLesson={onSelectLesson} />
        </div>
    )
}

export default Classroom;