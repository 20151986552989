import Helmet from 'react-helmet'

const HeaderTags = ({
  title = "讀聽格林", 
  description = "可看可聽可讀，讓你享受閱讀美好體驗。", 
  imageUrl = "https://d2cgzwr7140f0n.cloudfront.net/images/banner/%E9%A6%96%E9%A0%81BN_03.jpg"}) => {
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content={description} />
        <meta name="title" key="title" content={title} />
        <meta property="og:title" key="og:title" content={title} />
        <meta property="og:locale" key="og:locale" content="zh_TW" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={description}
        />
        <meta
          property="og:image"
          key="og:image"
          content={imageUrl}
        />  
      </Helmet>
    )
}

export default HeaderTags