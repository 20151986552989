import React from "react"
import { Navigation, Pagination, Autoplay, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
// import constants from "../../lib/constants"



function HomeBannerNew({ banners }) {
  return (
    <Swiper
      className="banner banner-home"
      modules={[Navigation, Pagination, Autoplay, A11y]}
      slidesPerView={1}
      navigation
      speed={800}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
    >
      {
        banners.map(banner => (
          <SwiperSlide>
            <img className='banner_pic' src={banner.pc} alt="" />
            <img className='banner_pic-m' src={banner.mobile} alt="" />
          </SwiperSlide>
        ))
      }
    </Swiper>
  )
}

export default HomeBannerNew
