import { React, useState } from "react"

import LoginLink from "./login-link"

import apiService from "../../service/apiService"
import userService from "../../service/userService"
import constants from "../../lib/constants"

function PopupLogin() {
  const [username, setUsername] = useState(userService.getUsername())
  const [password, setPassword] = useState("")
  const [errMsg, setErrMsg] = useState("")

  const showErrorMessage = (msg) => {
    setErrMsg(msg)
    setTimeout(() => {
      setErrMsg("")
    }, 5000)
  }

  const handleLineLogin = () => {
    const clientId = '2003899380';
    const redirectUri = constants.lineCallbackUrl;
    const state = 'login';
    const scope = 'profile openid';
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&scope=${scope}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (username.length === 0) {
      showErrorMessage('請輸入帳號 (e-mail)')
      return
    }
    if (password.length === 0) {
      showErrorMessage('請輸入密碼')
      return
    }
    try {
      const resp = await apiService.apiLogin({
        username,
        password,
      })
      if (resp.token) {
        userService.setUser(resp)
        userService.setUsername(username)
        window.location.href = "/"
      } else {
        showErrorMessage("登入失敗")
      }
    } catch (ex) {
      console.log(ex)
      // showErrorMessage(`${ex.response.data.error.message}`)
      showErrorMessage("帳號或密碼錯誤")
    } finally {
      // setUsername('')
      // setPassword('')
    }
  }

  const handlePasswordInput = (e) => {
    setPassword(e.target.value)
    // setMaskedPassword(e.target.value)
    e.target.type = 'text'
    setTimeout(() => {
      // setMaskedPassword(new Array(e.target.value.length+1).join("*"))
      e.target.type = 'password'
    }, 500);
  }

  return (
    <div className="popup-medium_content">
      <div className="d-flex flex-wrap align-items-end justify-content-between">
        <h5 className="popup_title login">登入</h5>
        <LoginLink />
      </div>
      <form className="popup_form">
        <input
          className="input-common"
          type="text"
          value={username}
          placeholder="帳號 (e-mail)"
          onChange={({ target }) => setUsername(target.value)}
        />
        <input
          className="input-common"
          type="text"
          value={password}
          placeholder="密碼"
          onChange={handlePasswordInput}
        />
        <button className="btn-common" type="button" onClick={handleSubmit}>
          登入
        </button>
        <p className="popup_hint">{errMsg}</p>
      </form>
      {/* <button className="line_login" onClick={handleLineLogin} /> */}
    </div>
  )
}

export default PopupLogin
