import React from 'react';

import DiscountPic from '../../img/pic-12.png';
import DiscountHead from '../../img/dicount_head.svg';
import IconCut from '../../img/icon_cut.svg';
import constants from '../../lib/constants';


function Discount({ discount, onUseCoupon }) {
    const handleUseCoupon = () => {
        onUseCoupon(discount.id)
    }

    return (
        <div className="member_discount">
            <div className="member_discount_inner">
                <div className="member_discount_left">
                    <div className="member_discount_pic"><img src={constants.imageUrl(discount.image)} alt="" /></div>
                    <ul className='member_discount_list'>
                        <li>
                            <span className="member_discount_listTitle">期限：</span>
                            <span className="member_discount_listContent">{discount.endDate}</span>
                        </li>
                        <li>
                            <span className="member_discount_listTitle">範圍：</span>
                            <span className="member_discount_listContent">{discount.scope}</span>
                        </li>
                    </ul>
                </div>
                <div className="member_discount_line">
                    <div className="member_discount_line_inner"></div>
                </div>
                <div className="member_discount_right">
                    <div className="member_discount_main">
                        <img className="member_discount_main_head" src={DiscountHead} alt="" />
                        <div className="member_discount_name">
                            <div className="member_discount_name_line"></div>
                            <h3>折價券</h3>
                        </div>
                        <div className="member_discount_title"><h4>{discount.name}</h4></div>
                        <div className="member_discount_en">
                            <div className="member_discount_en_line"></div>
                            <p>Discount Coupon</p>
                        </div>
                        <div className="member_discount_price"><p>NT${discount.discount}</p></div>
                    </div>
                    <button className='btn-icontext btn-use' onClick={handleUseCoupon}>
                        <img src={IconCut} alt="" />
                        <span>{discount.isInUse ? '使用中' : '立即使用'}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Discount;