import React from "react"

import IconLine from "../../img/icon_line-white.svg"

function SocialShare({ url }) {
  return (
    <div className="social-share d-flex align-items-center">
      {/* <div className="social-share_item social-share_fb d-flex align-items-center">
                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_861_15848)">
                        <path d="M23.894 10.1837C23.7871 9.28319 22.9794 8.63103 22.0783 8.63103H16.1317C16.057 8.63095 15.9833 8.61281 15.9169 8.57814C15.8506 8.54346 15.7934 8.49326 15.7502 8.43173C15.707 8.3702 15.6791 8.29914 15.6687 8.22449C15.6584 8.14985 15.6659 8.07379 15.6907 8.00268C16.1407 6.71083 16.512 5.37475 16.6413 4.00691C16.7617 2.72981 16.6683 1.03418 15.3915 0.347995C14.8391 0.0508358 13.9549 -0.135172 13.3609 0.121156C12.8131 0.357069 13.0009 1.20204 13.0009 1.66706C13.0009 2.37027 12.7287 3.03944 12.5183 3.69954C11.8591 4.62845 11.2809 5.60726 10.6138 6.53503C10.04 7.33804 9.34819 8.42234 8.3211 8.64577C8.08311 8.68959 7.86757 8.8153 7.71124 9.00145C7.55492 9.1876 7.46749 9.42266 7.46387 9.66655V20.9586C7.46343 21.0956 7.48981 21.2314 7.54151 21.3581C7.59321 21.4848 7.66921 21.6 7.76515 21.697C7.86109 21.7941 7.97509 21.8711 8.10061 21.9236C8.22612 21.9762 8.36069 22.0032 8.49659 22.0032H19.0004C19.9004 22.0032 20.7115 21.3431 20.8127 20.4369C20.8511 20.1053 20.7956 19.7696 20.6526 19.4686C20.5097 19.1675 20.2852 18.9134 20.005 18.7356C20.005 18.7356 20.005 18.7265 20.005 18.7265H20.0252C20.9252 18.7265 21.7352 18.0664 21.8364 17.1602C21.8749 16.8288 21.8196 16.4931 21.6768 16.1921C21.5341 15.891 21.3098 15.6368 21.0298 15.4589V15.4498H21.0557C21.9624 15.4498 22.7701 14.7863 22.868 13.8778C22.9049 13.5449 22.8473 13.2084 22.7019 12.9072C22.5565 12.606 22.3294 12.3527 22.0468 12.1765H22.1492C22.3985 12.1765 22.645 12.1231 22.8723 12.0197C23.0996 11.9163 23.3024 11.7654 23.4675 11.577C23.6325 11.3885 23.7558 11.1668 23.8294 10.9266C23.9029 10.6864 23.9249 10.4332 23.894 10.1837V10.1837Z" fill="#fff"/>
                        <path d="M5.58095 8.79785H0.883097C0.395376 8.79785 0 9.19647 0 9.6882V21.1095C0 21.6013 0.395376 21.9999 0.883097 21.9999H5.58095C6.06867 21.9999 6.46405 21.6013 6.46405 21.1095V9.6882C6.46405 9.19647 6.06867 8.79785 5.58095 8.79785Z" fill="#fff"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_861_15848">
                            <rect width="24" height="22" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span>讚</span>
                <span>3</span>
            </div> */}
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noreferrer">
        <div className="social-share_item social-share_fb fb-share ml-3">
          <span>分享</span>
        </div>
      </a>

      <a href={`https://social-plugins.line.me/lineit/share?url=${url}&openExternalBrowser=1`} target="_blank" rel="noreferrer">
        <div className="social-share_item social-share_line ml-3 mr-4 d-flex align-items-center">
          <img src={IconLine} alt="" />
          <span>分享</span>
        </div>
      </a>
    </div>
  )
}

export default SocialShare
