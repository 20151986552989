import React from 'react';
import { Link, useLocation } from "react-router-dom";

import userService from '../../service/userService';

function HeaderNav() {
    const path = useLocation().pathname;
    // Hide if not logged
    return userService.isLoggedIn() ? (
        <nav className='header_nav ml-4 d-md-block d-none'>
            <ul className='d-flex align-items-center'>
                <li className={`header_nav_item ${path.includes('watch') ? 'active' : ''}`}>
                    <Link to="/watch">看</Link>
                </li>
                <li className={`header_nav_item ${path.includes('listen') ? 'active' : ''}`}>
                    <Link to="/listen">聽</Link>
                </li>
                <li className={`header_nav_item ${path.includes('read') ? 'active' : ''}`}>
                    <Link to="/read">讀</Link>
                </li>
            </ul>
        </nav>
    ) : "";
}

export default HeaderNav;