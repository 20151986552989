import React from 'react';

import LessonTitle from './lesson-title';
import constants from '../../lib/constants';


const lessonTitle = "講師介紹";

function LessonTeacher({ instructors }) {
    return (
        <div className='lesson_content_item lesson_teacher'>
            <LessonTitle title={lessonTitle} />
            <div className="row">
                {
                    instructors?.map(item => (
                        <div className="col-md-6" key={item.id}>
                            <div className="lesson_teacher_item">
                                <div className="lesson_teacher_pic">
                                    <img src={item.image ? `${constants.imageBaseUrl}/${item.image}` : ''} alt={item.title} />
                                </div>
                                <h3 className="lesson_teacher_name">{item.title}</h3>
                                <p className="lesson_teacher_intro">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default LessonTeacher;