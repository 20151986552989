import { React, useEffect, useState } from 'react';

import Check from '../common/check';

import IconArrowWhite from "../../img/icon_arrow-white.svg"
import apiService from '../../service/apiService';

const sameBuyer = "同訂購人";

function OrderReceiver({ checkoutInfo, onChange, onSameBuyerChange }) {
    const [cities, setCities] = useState([
        '臺北市',
        '新北市',
        '基隆市',
        '宜蘭縣',
        '桃園市',
        '新竹縣',
        '新竹市',
        '苗栗縣',
        '臺中市',
        '彰化縣',
        '南投縣',
        '雲林縣',
        '嘉義縣',
        '嘉義市',
        '臺南市',
        '高雄市',
        '屏東縣',
        '臺東縣',
        '花蓮縣',
        '金門縣',
        '澎湖縣',
        '連江縣',
    ]);
    const [districts, setDistricts] = useState([]);

    const loadDistricts = async (city) => {
        try {
            const response = await apiService.listDistricts(city)
            setDistricts(response)
        } catch (error) {
            console.log(error)
        }
    }
    const onChangeCity = (e) => {
        // const city = e.target.value
        // loadDistricts(city)
        onChange(e)
    }

    useEffect(() => {
        if (checkoutInfo?.basicInfo.invoiceAddrCity) {
            loadDistricts(checkoutInfo.basicInfo.invoiceAddrCity)
        }
    }, [checkoutInfo?.basicInfo.invoiceAddrCity])

    return (
        <div className='account_setting'>
            <div className="d-flex align-items-center mb-30">
                <h2 className='mb-0 mr-4'>收件人</h2>
                <Check data={sameBuyer} onChange={(checked) => onSameBuyerChange(checked)} />
            </div>
            <div className="row d-block">
                <div className="col-xl-5 col-lg-7 account_setting_input">
                    <label htmlFor="">姓名</label>
                    <div className="account_setting_input_right">
                        <input className="input-common" type="text" placeholder="請填寫" name='invoiceName' value={checkoutInfo?.basicInfo.invoiceName} onChange={(e) => onChange(e)} />
                    </div>
                </div>
                <div className="col-xl-5 col-lg-7 account_setting_input">
                    <label htmlFor="">電話</label>
                    <div className="account_setting_input_right">
                        <input className="input-common" type="text" placeholder="請填寫" name='invoicePhone' value={checkoutInfo?.basicInfo.invoicePhone} onChange={(e) => onChange(e)} />
                    </div>
                </div>
                <div className="col-xl-5 col-lg-7 account_setting_input">
                    <label htmlFor="">電子信箱</label>
                    <div className="account_setting_input_right">
                        <input className="input-common" type="text" placeholder="請填寫" name='invoiceEmail' value={checkoutInfo?.basicInfo.invoiceEmail} onChange={(e) => onChange(e)} />
                    </div>
                </div>
                <div className="col-lg-10 account_setting_input receiver_adress">
                    <label htmlFor="">地址</label>
                    <div className="account_setting_input_right">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input">
                                    <div className="input_content"></div>
                                    <div className="input_btn">
                                        <img src={IconArrowWhite} alt="" />
                                    </div>
                                    <select name="invoiceAddrCity" id="invoiceAddrCity" className="input_select" value={checkoutInfo?.basicInfo.invoiceAddrCity ?? ''} onChange={(e) => onChangeCity(e)}>
                                        <option value="">請選擇</option>
                                        {cities.map(
                                            (city, index) => <option key={index} value={city}>{city}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input input-district">
                                    <div className="input_content"></div>
                                    <div className="input_btn">
                                        <img src={IconArrowWhite} alt="" />
                                    </div>
                                    <select name="invoiceAddrDistrict" id="invoiceAddrDistrict" className="input_select" value={checkoutInfo?.basicInfo.invoiceAddrDistrict ?? ''} onChange={(e) => onChange(e)}>
                                        <option value="">請選擇</option>
                                        {districts.map(
                                            (district, index) => <option key={index} value={district}>{district}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <input name='invoiceAddrLine' className="input-common" type="text" placeholder="巷弄號樓" value={checkoutInfo?.basicInfo.invoiceAddrLine} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderReceiver;