import React, { useEffect, useState } from 'react';

import LessonTitle from './lesson-title';
import RTUtils from '../../lib/rt-utils';

const lessonTitle = "限時倒數";

function LessonInform({ title, salesEnd }) {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        console.log(salesEnd)

        // set timer to update hours and minutes every minute
        const timer = setInterval(() => {
            const [days, hours, minutes, seconds] = RTUtils.timeTillDateTimeStr(salesEnd);
            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }, 1000);
        return () => clearInterval(timer);
    }, [salesEnd])

    return (
        <div className='lesson_content_item'>
            <LessonTitle title={lessonTitle} />
            <div className="lesson_reciprocal">
                <div className="d-md-flex align-items-center mb-md-4 mb-5">
                    <h3>剩餘時間</h3>
                    <p>{days > 0 && <> <span className='highlight'>{days}</span>天</>} <span className='highlight'>{hours}</span>小時 <span className='highlight'>{minutes}</span>分 <span className='highlight'>{seconds}</span>秒</p>
                </div>
                <div className="d-md-flex align-items-center">
                    <h3>目前區段方案</h3>
                    <p>{title}</p>
                </div>
            </div>
        </div>
    )
}

export default LessonInform;