import { React } from "react"

import PicCheck from "../../img/pic_check.svg"

function PopupHint(props) {
  return props.trigger ? (
    <div className="popup d-block">
      <div className="popup_inner popup-large popup-hint-box">
        <div className="row">
          <div className="col-md-6">
            <div className="popup_logo hint">
              <img src={PicCheck} alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="popup_right popup_right-hint">
              <svg
                className="popup-close"
                onClick={() => props.setTrigger(false)}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1.92188"
                  y="0.00390625"
                  width="32.6325"
                  height="2.71937"
                  transform="rotate(45 1.92188 0.00390625)"
                  fill="#979797"
                />
                <rect
                  y="23.0742"
                  width="32.6325"
                  height="2.71937"
                  transform="rotate(-45 0 23.0742)"
                  fill="#979797"
                />
              </svg>
              <h3>{props.title}</h3>
              <p>{props.message}</p>
              <button
                className="btn-common btn-hint_check"
                onClick={() => props.onOk()}
              >
                了解
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )
}

export default PopupHint
