import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import TitleList from '../common/title-list';
import CardLesson from './card_lesson';

function LessonList({ title, contentList, detailLink, requireLogin = false }) {
    useEffect(() => {
        console.log(contentList)
    }, [contentList])

    return (
        <div>
            <TitleList titleData={title} />
            <div className="row"> {/* "row" has problem 767w-1199w */}
                {
                    contentList?.map((content) => (
                        <div className="col-xl-4 col-md-6" key={content.seriesId} >
                            <CardLesson lessonData={content} requireLogin={requireLogin} />
                        </div>
                    ))
                }
            </div>
        </div >
    );
}

export default LessonList;