import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import BackArrow from "../../img/icon_arrow-small-deep.svg"
import ReactPlayer from "react-player/lazy"

import constants from "../../lib/constants"
import apiService from "../../service/apiService"

function Video({ product }) {
  const [player, setPlayer] = useState()

  const navigate = useNavigate()
  // Call setHistory
  const trackUsage = async (progress) => {
    // const resp = 
    await apiService.apiPostHistory({ productId: product.id, progress: progress.playedSeconds})
    // console.log(resp)
  }

  useEffect(() => {
    const loadHistory = async () => {
      const params = {productId: product.id}
      // console.log(params)
      const resp = await apiService.apiGetHistory(params)
      if (resp.totalElements === 0) return
      // console.log(`loaded progress = ${resp.content[0].progress}`)
      player.seekTo(resp.content[0].progress)
    }
    if (product.id && player) {
      loadHistory()
    }
  }, [player, product])

  return (
    <div className="banner-inner banner-video" onContextMenu={(e) => e.preventDefault()}>
      <div className="banner-video_inner">
        <ReactPlayer
          url={product.contentUri}
          controls
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          ref={(ele) => { setPlayer(ele) }}
          progressInterval={5000}
          onProgress={trackUsage}
          playing={true}
        />
      </div>
      <button className="btn-icon btn-back" onClick={() => navigate(-1)}>
        <img src={BackArrow} alt="" />
      </button>
    </div>
  )
}

export default Video
