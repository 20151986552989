import React from 'react';

import LessonTitle from './lesson-title';

const lessonTitle = "課程資訊";

function LessonInform({ data }) {

    const minutesToHoursAndMinutes = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainMinutes = minutes % 60;
        return [hours, remainMinutes]
    }
    const [hours, minutes] = minutesToHoursAndMinutes(data.duration);
    return (
        <div className='lesson_content_item'>
            <LessonTitle title={lessonTitle} />
            <div className="lesson_inform">
                <div className="lesson_inform_item openDate">
                    <h3>開課時間</h3>
                    <p>
                        <span className='d-md-block d-none'>{data.classStartDate}</span>
                        <span className='d-md-none'>{data.classStartDate}</span>
                    </p>
                </div>
                <div className="lesson_inform_item">
                    <h3>課程時長</h3>
                    <p>{hours}<span className='text-small'>小時</span> {minutes}<span className='text-small'>分鐘</span></p>
                </div>
                <div className="lesson_inform_item">
                    <h3>課程單元</h3>
                    <p>{data.unitsCount}<span className='text-small'>個</span></p>
                </div>
            </div>
        </div>
    )
}

export default LessonInform;