import React, { useEffect, useState } from "react"

import ListBannerStatic from '../component/list/list-banner-static';
import TitleContent from '../component/common/title-content';
import AboutItem from '../component/content/about-item';

import Banner from '../img/banner-pic.png';
import BannerM from '../img/pic-3.png';
import AboutPic from '../img/pic10.png';
import apiService from "../service/apiService"

const title = "關於格林";
const about = [
    {
        title: "格林LOGO故事",
        content: <div>
            格林的商標──雞、貓、狗、驢四隻動物疊在一起的圖案，取材於格林童話《布萊梅樂隊》。 <br />
            《布萊梅樂隊》是敘述四隻被主人遺棄的動物──年老力衰的老驢子、不能打獵的老狗、抓不到老鼠的老貓、和嗓子啞了的公雞，計畫到大城市布萊梅組一支樂隊，開始牠們的新生活。 <br />
            <br />
            但是當牠們穿越森林時，卻意外的闖進強盜窩。為了趕走強盜，牠們想了一個主意。驢把兩隻前蹄搭在窗台上、狗跳到驢背上，貓爬到狗背上，公雞飛到貓頭上，然後四隻動物一起奏樂：驢子嘶吼、狗吠、貓叫、公雞喔喔啼。 <br />
            屋裡的強盜聽到這個恐怖的聲音，以為鬼怪要衝進來，非常害怕，通通逃走了。從此，這四個好朋友快快樂樂的在森林裡住下來。 <br />
            <br />
            《布萊梅樂隊》傳遞了「團結力量大」的重要性。出版一本好書，也需要大家的共同努力。首先我們要有好的作者、畫家創作，接下來要靠編輯的潤稿、美術編排的設計美化，印刷和裝訂才能成冊。 <br />
            <br />
            格林文化選擇用「布萊梅樂隊」作為LOGO有兩個意義：首先《布萊梅樂隊》是格林童話中的一個故事，而格林文化取名「格林」，就是想表達我們是一個出版兒童繪本的專業出版社；同時，格林文化希望能藉著這個LOGO提醒自己，在出版過程中不可疏漏任何一環節。 <br />
            <br />
            就像故事中的雞、貓、狗、驢一樣，我們秉持著團隊精神製作兒童繪本，在總編輯郝廣才先生的帶領下，格林文化自1993年成立以來，出版多本風格優美、藝術品味高的精緻圖畫書，並屢屢獲得國內外的插畫大獎。 <br />
        </div>,
        pic: '',
    },
    {
        title: "公司簡介",
        content: <div>
            格林文化是台灣第一家結合全球32個國家、335位世界第一流插畫家，出版「高畫質」兒童繪本的專業出版社。諾貝爾文學獎得主馬奎斯、吉卜林……，國際繪本大獎安徒生大獎得主杜桑凱利、羅伯英潘……等傑出而具影響力的作家和插畫家，都是我們的作、繪者。<br />
            <br />
            格林文化的圖畫書版權銷售世界各地，擁有25種不同語文的版本，包括：美、法、英、加、韓、德、日……等國家。不僅拓展台灣出版事業的版圖，也讓全世界知道台灣是一個有能力創造高品質、精緻文化出版品的地方。<br />
            <br />
            出版充滿豐富美感的兒童繪本、提昇兒童欣賞藝術的能力，並達到叫好又叫座的標準，是格林一直以來努力獲得的最大成就，也是未來持續不斷的原則與目標。
        </div>,
        pic: '',
    },
    {
        title: "得獎紀錄",
        content: <div>
            格林文化的書經常得到國際插畫大獎，如布拉迪斯國際插畫雙年展入選、波隆那國際兒童書插畫展入選、加泰隆尼亞國際插畫雙年展入選、聯合國兒童救援基金會最佳插畫家獎，不勝枚舉。並且連續七年成為「波隆那國際兒童書插畫展」作品入選最多的出版社。創下波隆那國際兒童書插畫展中，第一家出版社連續七年入選最多書籍的紀錄。1995年，更得到布拉迪斯國際插畫雙年展主辦單位評選為世界最佳出版社的榮譽。

            國內大獎中，格林文化的圖書也是常客，例如行政院文建會主辦之「好書大家讀」、文化部「中小學生優良課外讀物推介」、「金鼎獎」等，深受評審委員的青睞。
        </div>,
        pic: '',
    },
    {
        title: "製作能力",
        content: <div>
            （一）編輯：

            格林文化發行人郝廣才先生為台灣兒童進入繪本時代的關鍵人物，他擔任波隆那國際兒童書插畫展評審時，是歷來年紀最輕且為第一位擔任評審的華人，更曾獲選為「美國大亨名人錄」、「美國馬奎斯世界名人錄」的風雲人物之一。格林文化在他的帶領下，不論文字或美術編輯均具備豐富的兒童圖畫書相關編輯經驗。除圖書的編輯之外，與作、繪者的互動也是編輯工作中不容忽視的一環，格林文化與作、繪者溝通一向無礙，這點可由同一作、繪者常有不少作品是由格林獨家出版中發現。因為這些作、繪者相信，格林文化不僅站在消費者的立場，也站在他們的立場思考，研究如何能將其作品作最好的呈現。
            <br /><br />
            （二）印刷製作：

            格林文化圖書不但在編輯上費盡心思，在印刷製作上也絲毫不敢懈怠，為了呈現最好的閱讀品質，每本書都挑選合適的尺寸與用紙，即使某些特殊尺寸和紙張，可能導致裝訂成本增加，或必須在國外印刷，我們仍堅持採用，因為孩子的童年只有一次，為了給孩子最完整的美好，我們願意努力克服印製條件的限制。
        </div>,
        pic: '',
    },
]


function About() {
    const [banners, setBanners] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const banners = await apiService.apiHomeBanners({ location: "TOP" })
            setBanners(banners)
        }
        fetchData()
    }, [])

    return (
        <div>
            <ListBannerStatic banners={banners} />
            <div className="container about">
                <TitleContent titleData={title} />
                <AboutItem about={about} />
            </div>
        </div>
    )
}

export default About;