import React from 'react';

import LessonTitle from './lesson-title';
import LessonChapter from './lesson-chapter';


function ClassroomContent({ title, chapter, mylesson }) {
    return (
        <div className='classroom_content'>
            <h1 className='classroom_content_title'>{title}</h1>
            <LessonTitle title={"課程章節"} />
            <LessonChapter chapter={chapter} mylesson={mylesson} />
        </div>
    )
}

export default ClassroomContent;