import {React, useState} from 'react';
import { Link } from "react-router-dom";

import HeaderNavMLoginStatic from './header_nav-m-login-static-edit';
import PopupLogin from '../popup/popup-login';
import PopupLoginTemplate from '../popup/popup-login-template';

import IconCart from '../../img/icon_cart.svg';
import IconUser from '../../img/icon_user.svg';
import IconMenu from '../../img/icon_menu.svg';
import IconCross from '../../img/icon_cross.svg';

function handleActive(event) {
    if (event.currentTarget.classList.value.includes("active"))
        event.currentTarget.classList.remove('active') ;
      else
        event.currentTarget.classList.toggle('active') ;
}

function HeaderIconLoginStatic(){
    const [buttonPopupLogin, setButtonPopupLogin] = useState(false);
    return (
        <nav className='header_icon login'>
            <PopupLoginTemplate trigger={buttonPopupLogin} setTrigger={setButtonPopupLogin}>
                <PopupLogin />
            </PopupLoginTemplate>
            <ul className='header_icon_first d-flex align-items-center'>
                {/* 新增 */}
                <li className="header_navText d-md-block d-none"><a href="">我的課程</a></li>
                {/* 新增 */}
                <li className='header_cart'>
                    <a href="">
                        <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className='icon_fill' d="M24.6152 24.6144C24.6152 25.2936 24.8569 25.8748 25.3369 26.3548C25.8169 26.8348 26.3981 27.0764 27.0773 27.0764C27.7565 27.0764 28.3377 26.8348 28.8177 26.3548C29.2977 25.8748 29.5393 25.2936 29.5393 24.6144C29.5393 23.9352 29.2977 23.354 28.8177 22.874C28.3377 22.394 27.7565 22.1523 27.0773 22.1523C26.3981 22.1523 25.8169 22.394 25.3369 22.874C24.8569 23.3572 24.6152 23.9352 24.6152 24.6144Z" fill="#575757"/>
                            <path className='icon_fill' d="M31.6343 2.82449C31.3894 2.57959 31.102 2.45878 30.769 2.45878H7.67347C7.66041 2.37061 7.63102 2.20735 7.58531 1.96898C7.53959 1.73061 7.50367 1.54122 7.48082 1.40082C7.45469 1.26041 7.40571 1.09061 7.33714 0.891428C7.26531 0.692245 7.18367 0.53551 7.08571 0.421224C6.99102 0.306939 6.86041 0.205714 6.70041 0.124081C6.54041 0.0424488 6.35755 0 6.15184 0H1.23102C0.897959 0 0.610612 0.120816 0.365714 0.365714C0.120816 0.607347 0 0.897959 0 1.23102C0 1.56408 0.120816 1.85143 0.365714 2.09633C0.610612 2.34122 0.897959 2.46204 1.23102 2.46204H5.15265L8.5551 18.289C8.52898 18.3412 8.39837 18.5829 8.16 19.0204C7.92163 19.458 7.73224 19.8367 7.59184 20.1633C7.45143 20.4898 7.37959 20.7445 7.37959 20.9241C7.37959 21.2571 7.50041 21.5445 7.74531 21.7894C7.9902 22.031 8.27755 22.1551 8.61061 22.1551H9.84163H27.0727H28.3037C28.6367 22.1551 28.9241 22.0343 29.169 21.7894C29.4139 21.5445 29.5347 21.2571 29.5347 20.9241C29.5347 20.591 29.4139 20.3037 29.169 20.0588C28.9241 19.8139 28.6367 19.6931 28.3037 19.6931H10.6155C10.9224 19.0792 11.0759 18.6678 11.0759 18.462C11.0759 18.3347 11.0596 18.191 11.0269 18.0375C10.9943 17.8841 10.9551 17.7143 10.9127 17.5282C10.8669 17.342 10.8376 17.2049 10.8278 17.1167L30.9061 14.769C31.2261 14.7298 31.4906 14.5927 31.6931 14.3543C31.8988 14.1159 32 13.8449 32 13.538V3.69306C32 3.36 31.8792 3.06939 31.6343 2.82449Z" fill="#575757"/>
                            <path className='icon_fill' d="M7.38281 24.6144C7.38281 25.2936 7.62445 25.8748 8.10445 26.3548C8.58444 26.8348 9.16567 27.0764 9.84485 27.0764C10.524 27.0764 11.1053 26.8348 11.5853 26.3548C12.0653 25.8748 12.3069 25.2936 12.3069 24.6144C12.3069 23.9352 12.0653 23.354 11.5853 22.874C11.1053 22.394 10.524 22.1523 9.84485 22.1523C9.16567 22.1523 8.58444 22.394 8.10445 22.874C7.62445 23.3572 7.38281 23.9352 7.38281 24.6144Z" fill="#575757"/>
                        </svg>
                    </a>
                    {/* 新增 */}
                    <div className="header_cart_num">99</div>
                    {/* 新增 */}
                </li>
                <li className='d-md-block d-none'>
                    <a className='header_icon_notlogin'><img src={IconUser} alt="" /></a>
                    <a className='header_icon_login'><span>H</span></a>
                    <div className='header_icon_second'>
                        <ul>
                            <li><Link to="/member-favorite">會員專區</Link></li>
                            <li
                                onClick={() => setButtonPopupLogin(true)}
                            ><a>登入</a></li>
                        </ul>
                    </div>
                </li>
                <li className="header_menu d-md-none d-block" onClick={handleActive}>
                    <img className='header_menu_normal' src={IconMenu} alt="" />
                    <img className='header_menu_active' src={IconCross} alt="" />
                    <HeaderNavMLoginStatic />
                </li>
            </ul>
        </nav>
    );
}

export default HeaderIconLoginStatic;