import { useEffect, useState } from "react";
import userService from "../service/userService";

function useUserStatus({caller}) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    setIsLoggedIn(userService.isLoggedIn())
    // if (process.env.NODE_ENV !== 'production' && isLoggedIn) {
    //   console.log(userService.getUser())
    // }
  }, [isLoggedIn, caller])
  return isLoggedIn
}
export default useUserStatus