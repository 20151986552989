import React, { useState } from 'react';

import IconSearch from '../../img/icon_search.svg';

function FilterSearch({keyword, onChange}){
  const [keyword1, setKeyword] = useState(keyword)
  const handleSearch = (e) => {
    e.preventDefault()
    onChange(keyword1)
  }
    return (
        <div>
            <label className='input_label' htmlFor="">關鍵字</label>
            <div className="input">
                <input className='input_content' type="text" placeholder='請輸入關鍵字' value={keyword1} onChange={({target}) => setKeyword(target.value)}/>
                <button className='input_btn' onClick={handleSearch}><img src={IconSearch} alt="" /></button>
            </div>
        </div>
    );
}

export default FilterSearch;