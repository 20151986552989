import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import apiService from "../service/apiService"
import userService from "../service/userService"

function LineAuth() {
    const [searchParams] = useSearchParams()
    const code = searchParams.get("code")
    const state = searchParams.get("state")
    const navigation = useNavigate()

    useEffect(() => {
        const lineConnect = async () => {
            try {
                const resp = await apiService.lineConnect({ code, state })
                console.log(resp)
                navigation("/member-account-static-edit")
            } catch (e) {
                console.log(e)
            }
        }

        const lineLogin = async () => {
            try {
                const resp = await apiService.lineLogin({ code, state })
                console.log(resp)
                if (resp.token) {
                    userService.setUser(resp)
                    userService.setUsername(resp.username)
                    window.location.href = "/"
                } else {
                    alert("登入失敗")
                }
            } catch (e) {
                console.log(e)
                alert("登入失敗")
                window.location.href = "/"
            }
        }


        if (code && state) {
            console.log("code", code)
            console.log("state", state)
            if (state === 'connect') {
                lineConnect()
            } else if (state === 'login') {
                lineLogin()
            } else {
                console.log('Invalid state')
            }
        }
    }, [code, state])

    return (
        <div>
            <h1>LineAuth</h1>
        </div>
    )
}

export default LineAuth