import React, { useState } from "react"
import apiService from "../../service/apiService"

function Exchange({onRedeemSuccess}) {
  const [serialNumber, setSerialNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const resp = await apiService.apiRedeem({serialNumber: serialNumber})
      // console.log(resp)
      onRedeemSuccess(resp.name)
    } catch (e) {
      console.log(e)
      showErrorMessage(`${e.data.error.message}`)
    }
  }

  const showErrorMessage = (msg) => {
    setErrorMessage(msg)
    setTimeout(() => {
      setErrorMessage('')
    }, 2000);
  }

  return (
    <div className="popup-medium_content">
      <h5 className="popup_title login">兌換</h5>
      <p className="popup_intro">請輸入您購買的產品序號，大小寫不限。</p>
      <form action="" className="popup_form">
        <input className="input-common" type="text" placeholder="請輸入序號" value={serialNumber} onChange={({target}) => setSerialNumber(target.value.toUpperCase())} />
        <button className="btn-common" onClick={(e) => handleSubmit(e)}>兌換</button>
        <p className="popup_hint">{errorMessage}</p>
      </form>
    </div>
  )
}

export default Exchange
