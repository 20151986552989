import React, { useEffect, useState } from 'react';

import Title from '../component/common/title';
import MemberNav from '../component/member/member-nav';
import PaginationStatic from '../component/common/pagination-static';
import ListItemFavoriteStatic from '../component/list/list-item-favorite-static-edit';

import iconHead from '../img/icon_head.svg';
import IconEmpty from '../img/icon_empty.svg';
import apiService from '../service/apiService';

const PageTitle = { pic: iconHead, name: "我的收藏", parent: "會員專區" };
const NavActiveIndex = 1;

function MemberFavoriteStatic() {
    const [collections, setCollections] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [reload, setReload] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myCollections = await apiService.getMyCollections()
                setCollections(myCollections.content)
                setPage(myCollections.pageNumber)
                setTotalPages(myCollections.totalPages)
            } catch (error) {
                console.log(error)
            } finally {
                setReload(false)
            }
        }
        if (reload) {
            fetchData()
        }
    }, [reload])

    const onClickDelete = async (collectionId) => {
        try {
            await apiService.deleteFromMyCollectionsById({ id: collectionId })
            setReload(true)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePrev = () => {
        if (page === 1) return
        setPage(page - 1)
    }

    const handleNext = () => {
        if (page === totalPages) return
        setPage(page + 1)
    }

    const handleFirst = () => {
        setPage(1)
    }

    const handleLast = () => {
        setPage(totalPages)
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    return (
        <div className='container'>
            <div className="title_content title-member">
                <Title data={PageTitle} />
            </div>
            <div className="member_page page">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 mb-4">
                        <MemberNav active={NavActiveIndex} />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="member_content">
                            <div className="row">
                                {
                                    collections?.map((data, index) => (
                                        <div className="col-lg-6" key={index}>
                                            <ListItemFavoriteStatic
                                                data={data}
                                                handleDelete={onClickDelete} />
                                        </div>
                                    ))
                                }

                            </div>
                            {!collections || collections.length === 0 &&
                                <div className="member_empty">
                                    <img src={IconEmpty} alt="" />
                                    <p>這裡空空的...</p>
                                </div>
                            }
                        </div>
                        {collections && collections.length > 0 &&
                            < PaginationStatic
                                page={page}
                                totalPages={totalPages}
                                onPrev={handlePrev}
                                onNext={handleNext}
                                onFirst={handleFirst}
                                onLast={handleLast}
                                onPageChange={handlePageChange}
                            />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberFavoriteStatic;