import React from 'react';
import { Link } from "react-router-dom";


function Category(){
    return (
        <nav className='my-5 category'>
            <div className="container d-flex">
                <div className='mr-5'>
                    <div className="d-flex container mb-5">
                        <div className="d-flex align-items-center mr-4">
                            <div className="color-box finish"></div>
                            <span>已完成</span>
                        </div>
                        <div className="d-flex align-items-center mr-4">
                            <div className="color-box notfinish"></div>
                            <span>未完成</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="color-box notwork"></div>
                            <span>未開始</span>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <Link target="_blank" to="/">01 首頁 登入前</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/home-notlogin">02 首頁 登入後</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/watch">08a 看 單元首頁</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/listen">08 聽 單元首頁</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/listen-content">09 聽：最新音檔</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/watch-content">09a 看：最新影片</Link>
                        </li>
                        <li>
                            <Link className='notfinish' target="_blank" to="/listen-inner">10 聽：內容頁：世紀今日</Link>
                        </li>
                        <li>
                            <Link className='notfinish' target="_blank" to="/listen-inner-nobar">10a bar隱藏版</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/watch-inner">10b 看：內容頁：世紀今日</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/read">11 讀 單元首頁</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/read-inner">12 讀：內容頁</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/term">12a 服務條款</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/member-favorite">13 會員專區：我的最愛</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/member-record">14 會員專區：兌換紀錄</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/member-account">15 會員專區：基本資料</Link>
                        </li>
                        <li className='mt-5'>
                            <Link className='' target="_blank" to="/popup-login">04a 登入</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-set-password-1">05a 重設密碼</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-set-password-2">05b 重設密碼</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-exchange">06 兌換</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-signin-1">06a 註冊</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-signin-2">06b 註冊</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-signin-3">06c 註冊</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-hint-1">07 提示1</Link>
                        </li>
                        <li>
                            <Link className='' target="_blank" to="/popup-hint-2">07 提示2</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className='mb-4'>V2更新</h3>
                    <ul className=''>
                        <li>
                            <Link target="_blank" to="/home-new">首頁(未登入)</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/home-new-login">首頁(登入)</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/listen">08 聽：單元首頁（只改icon）</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/watch">08a 看：單元首頁（只改icon）</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/read">11 讀：單元首頁（只改icon）</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/term">12a 服務條款</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/member-favorite-static-edit">13 會員專區：我的收藏（我的最愛）</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/member-record-static-edit">14 會員專區：我的訂單（兌換紀錄）</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/member-account-static-edit">15 會員專區：我的資料（帳號設定）</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/lesson">15課程列表頁</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/latest-lesson">16最新課程</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/lesson-content">17課程內容頁</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/classroom">18課程教室</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/cart">19購物車</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/order">20訂單資訊頁</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/member-mylesson">21會員專區：我的課程</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/member-discount">22會員專區：我的優惠</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/member-empty">23會員專區：空</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/about">24關於格林</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/service"> 25客服中心</Link>
                        </li>
                        <li>
                            <Link target="_blank" to="/qa">26常見問與答</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Category;