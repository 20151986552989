import { React, useEffect, useState } from "react"

import Check from "../common/check"
import IconArrowWhite from "../../img/icon_arrow-white.svg"

import RTUtils from "../../lib/rt-utils"
import TaiwanCities from "../../lib/tw-cities"
import apiService from "../../service/apiService"
import AgreementLink from "../popup/popup-agreement"
import userService from "../../service/userService"
import constants from "../../lib/constants"

const genderOptions = ["女", "男", "暫不提供"]
const years = RTUtils.generateYearsList()
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
const cities = TaiwanCities.cities
const familyOptions = constants.familyOptions
const topicOptions = constants.topicOptions

function PopupSignin() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [fullname, setFullname] = useState("")
  const [phone, setPhone] = useState("")
  const [gender, setGender] = useState("")
  const [birthYear, setBirthYear] = useState("")
  const [birthMonth, setBirthMonth] = useState("")
  const [address, setAddress] = useState("")
  const [family, setFamily] = useState("")
  const [kids, setKids] = useState([])
  const [kidYearOptions, setKidYearOptions] = useState([])
  const [topics, setTopics] = useState([])
  const [agreement, setAgreement] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [toggleState, setToggleState] = useState(1)
  const toggleTab = async (index) => {
    if (toggleState === 1 && index > toggleState && !await verifyStep1()) return
    if (toggleState === 2 && index > toggleState && !verifyStep2()) return
    // if (toggleState === 3 && (index !== toggleState) && !verifyStep3()) return
    setToggleState(index)
    setErrorMessage("")
  }

  const toggleTopic = (topic, isChecked) => {
    let list = topics.filter((i) => i !== topic)
    if (isChecked) {
      list.push(topic)
    }
    setTopics(list)
  }

  const onAgreementChecked = (e) => {
    setAgreement(!agreement)
  }

  const showErrorMessage = (msg) => {
    setErrorMessage(msg)
    setTimeout(() => {
      setErrorMessage("")
    }, 1500)
  }

  const verifyStep1 = async () => {
    if (!RTUtils.validateEmail(username)) {
      showErrorMessage("帳號請輸入有效電子郵件")
      return false
    }
    if (!RTUtils.validatePassword(password)) {
      showErrorMessage(
        // "密碼為6-20字元英數字，至少包含1個數字、1個大寫字母、1個小寫字母"
        "密碼為6-20字元英數字"
      )
      return false
    }
    if (password !== confirmPassword) {
      showErrorMessage("密碼與確認密碼必須相符")
      return false
    }
    try {
      const resp = await apiService.isUserExists({ username: username });
      console.log(`isUserExists: ${resp.exists}`)
      if (resp.exists) {
        showErrorMessage("此帳號已被註冊")
        return false
      }
    } catch (e) {
      console.log(e)
    }
    return true
  }

  const verifyStep2 = () => {
    if (!RTUtils.validateFullName(fullname)) {
      showErrorMessage("姓名長度過短")
      return false
    }
    if (!RTUtils.validateMobilePhoneTW(phone)) {
      showErrorMessage("手機號碼格式有誤")
      return false
    }
    if (gender.length === 0) {
      showErrorMessage("請選擇性別")
      return false
    }
    if (birthYear.length === 0) {
      showErrorMessage("請選擇出生年")
      return false
    }
    if (birthMonth.length === 0) {
      showErrorMessage("請選擇出生月")
      return false
    }
    if (address.length === 0) {
      showErrorMessage("請選擇居住地")
      return false
    }
    if (family.length === 0) {
      showErrorMessage("請選擇家庭狀況")
      return false
    }
    return true
  }

  const verifyStep3 = () => {
    if (topics.length === 0) {
      showErrorMessage("請勾選您有興趣的主題")
      return false
    }
    if (!agreement) {
      showErrorMessage("請勾選確認同意會員服務條款")
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    // verify inputs
    // if (!verifyStep1() || !verifyStep2() || !verifyStep3()) return
    if (! await verifyStep1()) {
      setToggleState(1)
    }
    if (!verifyStep2()) {
      setToggleState(2)
    }
    if (!verifyStep3()) return
    // call register API
    // console.log("go")
    const signupData = {
      email: username,
      password: password,
      fullName: fullname,
      phoneNumber: phone,
      gender: gender,
      birthYear: birthYear,
      birthMonth: birthMonth,
      address: address,
      family: family,
      kids: kids,
      topics: topics.join(','),
    }
    try {
      const resp = await apiService.apiSignup(signupData)
      // auto-login if signup success
      if (resp.token) {
        userService.setUser(resp)
        window.location.href = "/"
      }
    } catch (e) {
      console.log(e)
      // show alert
      setErrorMessage(`註冊失敗：${e.data.error.message}`)
    }
  }

  const handleFamilyChange = (v) => {
    setFamily(v)
    if (v === "沒有小孩") {
      setKids([])
    } else {
      setKids([{ name: "小孩1", year: "", month: "" }])
    }
  }

  return (
    <div className="position-relative sigin_popup_content">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="sigin_title d-flex align-items-center justify-content-sm-start justify-content-between">
          <h5 className="popup_title m-0">註冊</h5>
          <ul className="sigin_tab d-flex align-items-center ml-4">
            <li
              className={
                toggleState === 1 ? "active sigin_tab_item" : "sigin_tab_item"
              }
              onClick={() => toggleTab(1)}
            ></li>
            <li
              className={
                toggleState === 2 ? "active sigin_tab_item" : "sigin_tab_item"
              }
              onClick={async () => await toggleTab(2)}
            ></li>
            <li
              className={
                toggleState === 3 ? "active sigin_tab_item" : "sigin_tab_item"
              }
              onClick={() => toggleTab(3)}
            ></li>
          </ul>
        </div>
      </div>
      <div className="sigin_tab_content">
        <div
          className={
            toggleState === 1
              ? "sigin_tab_content_inner tab-1 active"
              : "sigin_tab_content_inner tab-1"
          }
        >
          <div className="sigin_link">{/* <SiginLink /> */}</div>
          <div className="popup_form sigin_form_step1">
            <input
              className="input-common"
              type="text"
              placeholder="帳號（請輸入有效電子郵件）"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
            />
            <input
              className="input-common"
              type="password"
              placeholder="密碼（6-20字元英數字）"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <input
              className="input-common"
              type="password"
              placeholder="確認密碼"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
            <button className="btn-common" onClick={async () => await toggleTab(2)}>
              下一頁
            </button>
            <p className="popup_hint">{errorMessage}</p>
          </div>
        </div>
        <div
          className={
            toggleState === 2
              ? "sigin_tab_content_inner tab-2 active"
              : "sigin_tab_content_inner tab-2"
          }
        >
          <div className="popup_form sigin_form">
            <div className="row">
              <div className="col-md-6">
                <input
                  className="input-common sigin_input"
                  type="text"
                  placeholder="姓名"
                  value={fullname}
                  onChange={({ target }) => setFullname(target.value)}
                />
                <input
                  className="input-common sigin_input"
                  type="phone"
                  placeholder="手機"
                  value={phone}
                  onChange={({ target }) => setPhone(target.value)}
                />
                <div className="input">
                  <div className="input_content"></div>
                  <div className="input_btn">
                    <img src={IconArrowWhite} alt="" />
                  </div>
                  <select
                    className="input_select"
                    value={gender}
                    onChange={({ target }) => setGender(target.value)}
                  >
                    <option value="" disabled>
                      性別
                    </option>
                    {genderOptions.map((g) => (
                      <option value={g} key={g}>
                        {g}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sigin_input birthday">
                  <div className="input year">
                    <div className="input_content"></div>
                    <div className="input_btn">
                      <img src={IconArrowWhite} alt="" />
                    </div>
                    <select
                      className="input_select"
                      value={birthYear}
                      onChange={({ target }) => setBirthYear(target.value)}
                    >
                      <option value="" disabled>
                        出生年
                      </option>
                      {years.map((year) => (
                        <option value={year} key={year}>
                          {year}年
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input month">
                    <div className="input_content"></div>
                    <div className="input_btn">
                      <img src={IconArrowWhite} alt="" />
                    </div>
                    <select
                      className="input_select"
                      value={birthMonth}
                      onChange={({ target }) => setBirthMonth(target.value)}
                    >
                      <option value="" disabled>
                        月
                      </option>
                      {months.map((m) => (
                        <option value={m} key={m}>
                          {m}月
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sigin_input">
                  <div className="input">
                    <div className="input_content"></div>
                    <div className="input_btn">
                      <img src={IconArrowWhite} alt="" />
                    </div>
                    <select
                      className="input_select"
                      defaultValue={0}
                      onChange={({ target }) => setAddress(target.value)}
                    >
                      <option value="0" disabled>
                        請選擇居住地
                      </option>
                      {cities.map((a) => (
                        <option value={a} key={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sigin_input">
                  <div className="input">
                    <div className="input_content"></div>
                    <div className="input_btn">
                      <img src={IconArrowWhite} alt="" />
                    </div>
                    <select
                      className="input_select"
                      value={family}
                      onChange={({ target }) => handleFamilyChange(target.value)}
                    >
                      <option value="" disabled>
                        家庭狀況
                      </option>
                      {familyOptions.map((a) => (
                        <option value={a} key={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-sm-center justify-content-between sigin_btn-box">
              <button className="btn-gray" onClick={() => toggleTab(1)}>
                上一頁
              </button>
              <button className="btn-common" onClick={() => toggleTab(3)}>
                下一頁
              </button>
            </div>
            <p className="popup_hint">{errorMessage}</p>
          </div>
        </div>
        <div
          className={
            toggleState === 3
              ? "sigin_tab_content_inner tab-3 active"
              : "sigin_tab_content_inner tab-3"
          }
        >
          <div className="popup_form sigin_form">
            <p>您有興趣的主題，可複選。</p>
            <div className="d-flex align-items-center flex-wrap sigin_check">
              {topicOptions.map((topic, index) => (
                <Check
                  data={topic}
                  checked={topics.includes(topic)}
                  onChange={(isChecked) => toggleTopic(topic, isChecked)}
                  key={index}
                />
              ))}
            </div>
            <div className="account_setting term">
              <div
                className={`check d-flex align-items-center justify-content-center ${agreement ? "active" : ""
                  }`}
                onClick={onAgreementChecked}
              >
                <div className="check_box"></div>
                <span>
                  我同意本站的
                  <AgreementLink />
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-sm-center justify-content-between sigin_btn-box">
              <button className="btn-gray" onClick={() => toggleTab(2)}>
                上一頁
              </button>
              <button className="btn-common" onClick={handleSubmit}>
                完成註冊
              </button>
            </div>
            <p className="popup_hint">{errorMessage}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupSignin
