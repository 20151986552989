import React from "react"
import '../../style/extra.scss'

function TermItem() {
  return (
    <div className="term_item">
      <h1 className="title_article title_article-third">一.認知與接受條款</h1>
      <p>
        格林文化事業股份有限公司依據本服務條款提供讀聽網站(https://www.readtoday.com.tw/)服務(以下簡稱「本服務」)。當會員完成讀聽格林網站會員註冊手續、或開始使用本服務時，即視為您已閱讀、瞭解並同意接受本服務條款之所有內容，並視為您已確認詳閱並同意個人資料保護告知暨同意事項。如您不同意「網路服務約定事項」之全部或部分內容，請勿註冊，並請立即停止使用網路服務。
      </p>
      <p>
        格林文化有權於任何時間修改或變更本服務條款之內容，修改後的服務條款內容將公佈在讀聽網站上，不再個別通知會員，建議會員隨時注意該等修改或變更。
      </p>

      <h1 className="title_article title_article-third">
        二.會員帳號、密碼、安全性及資料完整性
      </h1>
      <p>
        1.在使用讀聽格林網站服務以前，須經過完整的註冊及驗證程序，並依照本服務之提示提供您本人正確、最新及完整的資料。
      </p>
      <p>
        2.若會員提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料或有重覆註冊帳號等情事時，格林文化有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕會員使用本網服務。
      </p>
      <p>
        3.您在註冊時可以自行選擇一個您所使用的有效電子信箱，作為你的登入識別，並自行設定密碼。任何依照規定方法，輸入會員帳號及密碼與登入資料一致時，無論是否由本人親自輸入，均將推定為會員本人所使用，利用該密碼及帳號所進行的一切行動，會員本人應負完全責任，因此請妥善保管您的密碼。
      </p>
      <p>
        4.如您發現或懷疑您的帳號或密碼遭到盜用、冒用或有重複帳號時，請您立即通知我們(客服專線：02-2351-7251)，以利儘快採取適當之措施。
      </p>
      <p>
        5.網路帳號、密碼及網路權益，僅供讀聽會員個人使用及享有，不得轉讓、轉借或以任何方式提供他人使用，亦不得共用。
      </p>
      <h1 className="title_article title_article-third">
        三.兒童及青少年之保護
      </h1>
      <p>
        為確保兒童及青少年使用網路安全，避免隱私權受到侵犯，未滿十二歲之兒童使用本服務時，法定代理人(或監護人)應閱讀、瞭解並同意本會員服務條款之所有內容，方得使用或繼續使用本服務。當您使用或繼續使用本服務時，即表示您的法定代理人(或監護人)已閱讀、瞭解並同意接受本會員服務條款之所有內容及其後修改變更。如有進行金流交易(例如：產品服務購買等)時，應有法定代理人(或監護人)全程在旁陪伴或完成必要之流程。
      </p>

      <h1 className="title_article title_article-third">四.會員守法義務</h1>
      <p>
        會員承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。如會員利用網路服務從事侵害他人權利之行為，或犯罪活動，或任何違法行為，會員應自負全部法律責任，本公司及關係企業將立即撤銷會員資格，永久禁止使用網路服務，並請求因此所受之全部損害，包含但不限於商譽損失、裁判費及律師費等。
      </p>

      <h1 className="title_article title_article-third">
        五.個人資料安全與收集
      </h1>
      <p>
        讀聽網站遵循「個人資料保護法」之規定，盡力以合理之技術及程序，保障所有個人資料之安全，在未經會員同意下，不會將會員個人資料提供予與本服務無關之第三人。
      </p>
      <p>
        在會員與讀聽網站或格林文化關係企業接觸時，即可能被要求提供個人資料。讀聽網站與格林文化關係企業可能相互共享本項個人資料，其使用將遵守本隱私權政策，用以提供並改進我們的產品、服務、內容及廣告。
      </p>
      <p>
        會員同意讀聽格林網站及格林文化相關企業得不定期發送電子報或商品訊息(EDM)會員所登錄的電子信箱帳號，可能包含產品訊息、活動、公告、優惠訊息等。當會員收到訊息後表示拒絕接受行銷時，只要通知我們客服人員不希望列載於我們的電子報通訊名單上，讀聽網站將停止繼續發送訊息。
      </p>

      <h1 className="title_article title_article-third">
        六.網路服務的中斷或停止
      </h1>
      <p>
        如本公司及關係企業之電腦或資訊系統發生異常狀況，本公司及關係企業有權暫停提供網路服務，直至異常狀況排除。
      </p>

      <h1 className="title_article title_article-third">七.智慧財產權保護</h1>
      <p>
        公司及關係企業所使用軟體或程式、網站上所有內容，包含但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計等智慧財產權，屬於格林文化所有，或已取得權利人之同意及授權而使用。任何人包含會員本人，未事前取得本公司及關係企業或權利人書面同意及授權，均不得以任何方式使用。如違反，立即撤銷會員資格，永久禁止使用網路服務，並請求因此所受全部損害，包含但不限於商譽損失、裁判費及律師費等。{" "}
      </p>

      <h1 className="title_article title_article-third">八.終止服務</h1>
      <p>
        1.基於公司運作，本公司保留隨時停止提供會員服務之全部或一部之權利，除對於付費服務另行公告處理辦法，本公司不因此而對會員負賠償之責任。
      </p>
      <p>
        2.如會員違反本使用條款或各該會員服務之使用規範或約定，本公司保留隨時暫時停止提供服務、或終止提供服務之權利，且不因此而對會員負任何賠償或補償之責任。
      </p>

      <h1 className="title_article title_article-third">九.準據法與管轄法院</h1>
      <p>
        本服務條款之解釋與適用，以及與本服務條款有關，或會員與讀聽網站因交易行為而產生之爭議或糾紛，應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院，但若法律對於管轄法院另有強制規定者，仍應依其規定。
      </p>
    </div>
  )
}

export default TermItem
