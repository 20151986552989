import React from 'react';

// import TermItem from '../component/content/term-item';
import TitleContent from '../component/common/title-content';
import topImage from '../img/點讀音檔下載說明網頁.jpg';
import bottomImage from '../img/點讀筆按鍵功能說明.jpg';

function TellypenInstructions() {
    return (
        // <div className="container term">
        //     <TitleContent titleData={'點讀音檔下載說明'} />
        //     <div className="term_item">
        //         <p className="" style={{ color: 'red', marginTop: '-30px' }}>
        //             下載點讀音檔前請先完成課程兌換。
        //         </p>
        //     </div>
        //     <TitleContent titleData="點讀音按鍵功能說明" />
        //     <div className="term_item"></div>
        // </div>
        <div className="" style={{ backgroundColor: '#FCC800' }}>
            <img src={topImage} alt="點讀音檔下載說明" style={{ width: '100%' }} />
            <img src={bottomImage} alt="點讀筆按鍵功能說明" style={{ width: '100%', margin: 0, padding: 0 }} />
        </div>
    );
}

export default TellypenInstructions;
