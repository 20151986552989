import React, { useEffect, useState } from 'react';



function LessonMessageName({ name, time, isReply }) {
    const [userNameInitial, setUserNameInitial] = useState("")
    useEffect(() => {
        setUserNameInitial(name.charAt(0))
    }, [name])
    return (
        <div className='lesson_message_name'>
            <div className="lesson_message_name_pic"><span>{userNameInitial}</span></div>
            <div className="lesson_message_name_text">
                <p>{name}</p>
                {
                    isReply === true ?
                        "" :
                        <span>{time}</span>
                }
            </div>
        </div>
    )
}

export default LessonMessageName;