import { React, useEffect, useState } from 'react';

import LessonTitle from './lesson-title';

import IconCross from "../../img/icon_cross_dark.svg";
import constants from '../../lib/constants';
import RTUtils from '../../lib/rt-utils';
import useUserStatus from '../../hooks/useUserStatus';
import PubSub from "pubsub-js"
import apiService from '../../service/apiService';
import { useNavigate } from 'react-router';
import { ThreeDots } from 'react-loader-spinner';
import SweetAlert2 from 'react-sweetalert2';

const lessonTitle = "購買方案";

function LessonCase({ salesPlans, openState, toggleOpen }) {
    const navigation = useNavigate()
    const isLoggedIn = useUserStatus("")

    // for displaying countdown time
    const [updatedPlans, setUpdatedPlans] = useState(salesPlans);
    const [isLoading, setIsLoading] = useState(false);

    const [swalProps, setSwalProps] = useState({});
    const showAlert = (title, text) => {
        setSwalProps({
            show: true,
            title: title,
            text: text,
            icon: 'warning',
            confirmButtonColor: '#FCC800',
            iconColor: '#FCC800',
            onConfirm: () => setSwalProps({ show: false })
        });
    }

    useEffect(() => {
        const updateSalesPlans = () => {
            const plans = salesPlans.map(data => {
                return {
                    ...data,
                    timeRemaining: RTUtils.formatTimeRemaining(data.salesEnd)
                }
            });
            setUpdatedPlans(plans);
        }
        updateSalesPlans();
        const timer = setInterval(() => {
            updateSalesPlans();
        }, 1000);
        return () => clearInterval(timer);
    }, [salesPlans]);

    const onClickBuy = async (id) => {
        if (!isLoggedIn) {
            toggleOpen(0)
            PubSub.publish("REQUIRE_LOGIN", "/cart")
        } else {
            setIsLoading(true);
            try {
                await apiService.addToCart({ salesPlanId: id });
                PubSub.publish("UPDATE_CART_COUNT")
                navigation('/cart')
            } catch (error) {
                console.log(error);
                if (error.data.error?.message) {
                    showAlert(error.data.error.message, '如有任何問題，請聯絡客服人員。')
                }
            } finally {
                setIsLoading(false);
            }
        }
        console.log(`buy plan id: ${id}`);
    }

    return (
        <div className={openState === 1 ? 'lesson_case open' : 'lesson_case'}>
            <SweetAlert2 {...swalProps} />
            <button className='btn-icon ml-auto mb-2 d-lg-none d-flex' onClick={() => toggleOpen(0)}>
                <img src={IconCross} alt="" />
            </button>
            <div className="lesson_case_content container px-0">
                <LessonTitle title={lessonTitle} />
                <div className="row">
                    {
                        updatedPlans?.map((data, index) => (
                            <div className="col-lg-12 col-md-6" key={index}>
                                <div className="lesson_case_item">
                                    <h3 className="lesson_case_title">{data.name}</h3>
                                    <div className="lesson_case_main">
                                        <div className="lesson_case_pic">
                                            <img src={data.image ? `${constants.imageBaseUrl}/${data.image}` : ''} alt="" />
                                        </div>
                                        {data.price != data.priceSales && <>
                                            <p className="lesson_case_origin">NT$ {data.price}</p>
                                            <h4 className="lesson_case_price">NT$ {data.priceSales}</h4>
                                            <p className="lesson_case_time">限時倒數：{data.timeRemaining}</p>
                                        </>}
                                        {data.price == data.priceSales && <>
                                            <h4 className="lesson_case_price no_discount">NT$ {data.price}</h4>
                                        </>}
                                        <p className="lesson_case_intro" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                        {!isLoading &&
                                            <button className='btn-common' onClick={() => onClickBuy(data.id)}>加入購物車</button>}
                                        {isLoading && <ThreeDots
                                            visible={isLoading}
                                            width="50"
                                            height={30}
                                            color="#4fa94d"
                                            radius="9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{ display: "flex", justifyContent: "center" }}
                                            wrapperClass=""
                                        />}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default LessonCase;