import React from "react"
import { useNavigate } from "react-router-dom"
import BackArrow from "../../img/icon_arrow-small-deep.svg"
import constants from "../../lib/constants"

function InnerBanner({ product, backLink = "-1", folder = "product/" }) {
  const navigate = useNavigate()
  return (
    <div className="banner-inner">
      <div className="banner-inner_pic">
        <img src={`${constants.imageBaseUrl}/${folder}${product.image}`} alt="" />
      </div>
      <button className="btn-icon btn-back" onClick={() => navigate(backLink)}>
        <img src={BackArrow} alt="" />
      </button>
    </div>
  )
}

export default InnerBanner
