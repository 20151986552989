import React, { useEffect, useState } from 'react';

import FilterSearchStatic from '../../component/common/filter_search-static';
import FilterMonthStatic from '../../component/common/filter_month';
import FilterArrangeStatic from '../../component/common/filter_arrange';

function FilterStatic({ onFilterChange }) {
    const [keyword, setKeyword] = useState('')
    const [order, setOrder] = useState('desc')

    useEffect(() => {
        onFilterChange({ keyword, order })
    }, [keyword, order])

    return (
        <div className="filter">
            <form className="filter_item">
                <FilterSearchStatic onKeywordChange={(keyword) => setKeyword(keyword)} />
            </form>
            {/* <form className="filter_item">
                <FilterMonthStatic />
            </form> */}
            <form className="filter_item">
                <FilterArrangeStatic onChange={(val) => setOrder(val)} />
            </form>
        </div>
    );
}

export default FilterStatic;