import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import IconArrow from "../../img/icon_arrow-small-deep.svg"
import IconTag from "../../img/icon_tag.svg"
import IconTagActive from "../../img/icon_tag-active.svg"
import apiService from "../../service/apiService"

import "../../style/extra.scss"

function InnerIcon({ backLink, product }) {
  const navigate = useNavigate()
  const [isLike, setIsLike] = useState(false)

  useEffect(() => {
    setIsLike(product.isLiked)
  }, [product.isLiked])
  

  const params = {
    productId: product.id,
    isLike: !isLike,
  }
  const handleClick = async () => {
    try {
      // const resp = 
      await apiService.apiPostLikes(params)
      // console.log(resp)
      setIsLike(params.isLike)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div className="d-md-none d-block inner_icon">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="btn-icon btn-back" onClick={() => navigate(backLink)}>
          <img src={IconArrow} alt="回上頁" />
        </div>
        <div className={`btn-icon btn-favorite-m ${isLike ? "active" : ""}`} onClick={() => handleClick()}>
          <img className="icon-normal" src={IconTag} alt="" />
          <img className="icon-active" src={IconTagActive} alt="" />
        </div>
      </div>
    </div>
  )
}

export default InnerIcon
