import React from 'react';
import { Link } from "react-router-dom";

import TitleCommon from './title-common';

import IconUnion from '../../img/icon_union.svg';

function TitleList({ titleData }) {
    return (
        <div className="d-flex align-items-center justify-content-between title-list">
            <TitleCommon data={titleData} />
            <Link className='btn-icontext' to={titleData.link}>
                <img src={IconUnion} alt="" />
                <span>顯示全部</span>
            </Link>
        </div>
    );
}

export default TitleList;