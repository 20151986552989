import React from "react"
import { Link } from "react-router-dom"
import HeaderNav from "../component/header/header_nav"
import HeaderNavStatic from "../component/header/header_nav-static"
import HeaderIconLoginStatic from "../component/header/header_icon-login-static-edit"
import logo from "../img/logo.svg"

function Header() {
  return (
    <header className="header">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="header_left d-flex align-items-center">
          <Link to="/">
            <img className="header_logo" src={logo} alt="讀聽格林" />
          </Link>
          <HeaderNavStatic />
        </div>
        <div className="header_right">
          <HeaderIconLoginStatic />
        </div>
      </div>
    </header>
  )
}

export default Header
